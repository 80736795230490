import React from "react";

function Seventh() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 459 918"
      xmlSpace="preserve"
    >
      <g id="cycle7">
        <path
          fill="#7C6A5C"
          d="M-144 523.9c.2.3.4.7.6 1 .2.5.4.9.6 1.4 0 .1-.1.3-.2.5-.6 1.1-1.5 1.9-2.7 2.4-1.6.9-3.3 1.7-5 2.2-1.8.6-3.7.7-5.6.5-.9-.1-.7-.2-2.7-.6-.4-.1-1.5-.3-5.1-.8-1.4-.2-2.8-.4-4.2-.7-2.9-.8-3.3-1.5-3.5-1.9-.3-.8-.2-1.6.2-2.4l27.6-1.6z"
        ></path>
        <path
          fill="#2B4421"
          d="M-156.4 517.5c.4.8 1 1.6 1.7 2.2.5.5 1.1.8 1.8 1.2.7.4 1.4.8 2.1 1 1.6.4 2.1-.4 4.3 0 .5.1 1.1.2 1.5.6.5.4.9 1 1.1 1.6.2 1.9-5 5-10.6 5.5-2.1.2-4.3 0-6.4-.4-3.7-.7-7.4-1.9-10.7-3.7l1.2-7.2 14-.8z"
        ></path>
        <path
          fill="#7C6A5C"
          d="M-178 518.1c-.2.3-.3.7-.5 1-.3.4-.6.9-.9 1.2-.2 0-.3.1-.5.1-1.2.1-2.4-.3-3.4-1-1.6-.8-3.2-1.9-4.5-3.1-1.4-1.2-2.6-2.7-3.4-4.4-.4-.9-.2-.7-.9-2.6-.2-.4-.5-1.5-2-4.8-.6-1.3-1.2-2.6-1.6-3.9-.9-2.9-.5-3.6-.3-3.9.5-.6 1.3-1 2.1-1.1l15.9 22.5z"
        ></path>
        <path
          fill="#2B4421"
          d="M-179.2 504.2c-.5.8-.8 1.7-.9 2.6-.1.7-.1 1.4-.1 2.1 0 .8.1 1.6.3 2.3.5 1.5 1.5 1.6 2.3 3.6.2.5.3 1 .3 1.6-.1.7-.4 1.3-.8 1.8-1.5 1.2-6.9-1.6-10.3-6-1.3-1.7-2.3-3.6-3-5.6-1.4-3.5-2.3-7.3-2.6-11.1l6.8-2.8 8 11.5z"
        ></path>
        <path
          fill="#1B2341"
          d="M-161.5 425.6c.2-.2.3-.4.5-.4 1 0 1 3.4 3.3 6.3.9 1.1 1.7 1.3 2.6 2.2.4.4.5.9 1 1.1 1 .4 1.7-.5 2.4-.4 1.8.2 3.3 8 2.6 17.9-.2 3.3-.7 6.5-1.1 10-1 7.1-1.2 6.8-1.3 9.3.1 2.7 0 5.4-.4 8.1-.1 1-.3 2-.6 3-1.3 3.1-4.6 4.4-6.4 5-2.6 1-5.2 2.3-7.6 3.8-2.7 1.8-5.3 3.9-7.6 6.2-1.3 1-2.4 2.2-3.3 3.6-.8 1.4-.9 2.4-1.9 2.9-.9.3-2 .2-2.8-.3-2.6-1.1-3.8-3.5-5.3-6.6s-1.3-3.9-1-4.2c.5-.6 1.2-.8 2-.8.9-1 1.9-1.9 2.9-2.9 1.7-1.6 3.3-3 4.9-4.3 0 0 12.1-8.1 12.8-10.3 0-.1.1-.2.1-.2.1-.5.2-1 .1-1.4-.3-.9-.7-1.7-1.2-2.5-.7-1.3-.3-1.4-1.5-4.4-.4-1.1-.4-1-.5-1.3-.3-1.2.1-2 .3-3.3.1-1.1 0-2.2-.3-3.3-.8-4.1-2-8.1-3.4-12.1-3.4-2.9-3.9-4.8-3.6-5.9.2-1.2 1-1.3 1.3-2.6.5-2.2-1.2-3.6-1.6-5.3-.4-1.7.3-4.6 5.6-9.3 6 3.2 8.2 3.2 9 2.4z"
        ></path>
        <path
          fill="#D89E94"
          d="M-155.6 381.5c-3-3.3-7.6-4.6-11.9-3.4.6-1.2 1-2.5 1-3.9-.1-1.6-.5-3.3-1.1-4.8-.2-.5-.3-.9-.4-.9-1.3-3.5-1.3-13.6 4.1-16.3 5-2.4 12.2 2.7 13.2 5.1.4 1.3.8 2.5 1.4 3.7.2.5.4 1 .5 1.5.2.5.2 1.1.1 1.6s-.3.6-.4 1c-.1.6.3 1.1.8 2 .5.9 1.3 2.3.9 3-.3.5-.9.3-1.2.8s.2 1.1-.3 1.7c-.1.2-.2.2-.3.4s0 .7-.1.9c-.1.2-.2 0-.3.1s-.3.4-.2 1.1c.1.7.1 1.4-.1 2-.5 1.4-3.3.7-4.8 2.4-.5.5-.9 1.2-.9 2z"
        ></path>
        <path
          fill="#220E0B"
          d="M-149 369.9c0-.1.3-.3.6-.2.1 0 .3 0 .3.2 0 .1 0 .2-.1.3-.1 0-.2 0-.4-.1l-.4-.2z"
          opacity="0.35"
        ></path>
        <linearGradient
          id="SVGID_1_"
          x1="-6245.24"
          x2="-6247.78"
          y1="432.83"
          y2="432.83"
          gradientTransform="rotate(180 -3197.86 398)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#3C3C3B"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M-148.9 364.6c-.1-.3-.2-.7-.3-1-.1-.3-.3-.6-.5-.8-.4-.3-.8-.4-1.3-.3l.4-.7c.5-.2 1-.1 1.5.2.5.4.7 1 .6 1.7 0 .3-.1.7-.4.9z"
        ></path>
        <linearGradient
          id="SVGID_2_"
          x1="-6249.474"
          x2="-6236.064"
          y1="418.334"
          y2="432.955"
          gradientTransform="rotate(180 -3197.86 398)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#1D1D1B" stopOpacity="0.47"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M-166.5 374.2c.2 0-.1-.9.4-2.3s1.3-2.7 2.4-3.7c1.2-1.1 1.8-1 2.1-1.8.4-1-.3-1.4.1-2.5.3-.9 1-1.6 2-1.8 1.4-.1 2.4 1.7 2.6 2.1.5.9.6 1.9.5 2.9l1.4-1.1c-.1-1.4.2-2.8.7-4.1.5-1.1 1-1.7.8-2.5-.3-1.2-1.5-1.6-1.3-2.1s1.3-.6 1.8-.6c1.1.1 2 .6 2.8 1.3.5-.2.9-.6 1.1-1.1.5-1.7-2.6-3.8-3-4-3.5-2.5-10-2.9-13.7.7-2 2-2.4 4.4-2.8 7.1-1.4 7.2 1.6 13.6 2.1 13.5z"
        ></path>
        <path
          fill="#995253"
          d="M-148.9 371.9c.1.1.2.3.1.4-.1.3-.5.4-.5.7 0 .1.1.2.1.3v.2c0 .1 0 .3-.1.3s-.3 0-.4-.1c-.2-.1-.3-.2-.4-.4-.3-.3-.2-.4-.3-.6s-.3-.3-.3-.4c0-.4 1.4-.9 1.8-.4z"
        ></path>
        <path
          fill="#6D5151"
          d="M-150.2 372.4c.3 0 .6 0 .8.3.1.1.1.2.2.4-.1-.1-.2-.2-.3-.4-.2-.2-.4-.3-.7-.3z"
        ></path>
        <path
          fill="#C18C86"
          d="M-151 364.3h.9c.1 0 .3.1.4.1.1.1.1.2.2.2s.2-.1.2-.2c0-.2-.6-.4-1-.4h-.8"
        ></path>
        <path
          fill="#C18C86"
          d="M-151 364.5c.2.1.4.3.5.5.1.2.2.3.3.4l.2.2v-.4c-.1-.2-.1-.3-.3-.5-.2-.3-.5-.5-.9-.7"
        ></path>
        <path
          fill="#EBEBEB"
          d="M-150 364.4v.3c0 .2.1.3.3.5-.1.1-.1.3-.2.4l-.1.1c0-.2-.1-.3-.1-.5-.1-.3-.4-.6-.7-.8h.8z"
        ></path>
        <path
          fill="#0F0F0F"
          d="M-150 364.4c.1 0 .4.1.4.2s-.1.2-.1.4v.1c-.1-.1-.2-.2-.2-.4-.1-.1-.1-.2-.1-.3z"
        ></path>
        <path
          fill="#220E0B"
          d="M-151.3 378.7c0-.1-2.3-.4-4.1-.8-2.4-.6-4.8-1.6-6.9-3 1.7 1.3 3.6 2.4 5.6 3.2.7.3 1.5.5 2.3.5.4 0 .6-.1 1.2-.1s1.3.1 1.9.2z"
          opacity="0.16"
        ></path>
        <path
          fill="#220E0B"
          d="M-150 368.8c0 .5.2 1 .5 1.4.2.2.5.3.8.4-.3-.1-.5-.3-.7-.6-.3-.4-.5-.8-.6-1.2z"
          opacity="0.08"
        ></path>
        <path
          fill="#1D1D1B"
          d="M-150.6 356.7c.2.4.2.8.2 1.3-.3-.2-.6-.3-.9-.4-1.5-.6-3.2-.9-4.8-.7.4-.6.9-1.1 1.5-1.4 1.4-.8 3.2-.3 3.9 1.1l.1.1z"
          opacity="0.34"
        ></path>
        <path
          fill="#432918"
          d="M-159.8 363.4c-.1-.1-.6.3-.8.8-.1.9.3 1.7 1 2.2-.2-.5-.4-1.1-.4-1.7.1-.7.4-1.2.2-1.3z"
          opacity="0.15"
        ></path>
        <path
          fill="#222955"
          d="M-150.3 434.9c.2-.2.4-.2.6-.2 1 .3.8 4.6.7 8.4-.4 10.7.1 8.6-.3 12.7-.8 8.7-3.4 20.8-3.9 23.6 0 .1-.1.5-.1 1s0 1.1.1 1.6c.4 2.5-1.7 4.8-2.6 7.7-1.7 5.4 2.3 6.9.4 11.9-.7 1.8-2.2 4-1.7 6.3.2.8.3 1.6.4 2.4.2.8.2 1.7.2 2.5-.2 1-.5 1.5-.2 2 .2.4.5.4.8.8.4.9.5 1.9.2 2.8-.9 2.5-4.9 2.9-6.4 3-2.8.2-5.5-.4-7.9-1.7-1.2-4.5-.8-6.1-.1-6.6.3-.2 1-.5 1.1-1s-.5-1.1-.6-1.3c-1.7-1.7-.4-11.2 0-18.3.1-2.2.5-9.4 1.8-11.4.3-.5.5-1 .6-1.6.1-.9-.1-1.9-.5-2.7-.5-1.6 0-2-.2-4.6 0-.5-.1-1-.1-1.4-.1-.9-.3-1.8-.5-2.7v-.2c-.7-2.4-.3-8-2.1-12.3-.4-1-1-1.9-1.5-2.8-.8-1.3-1.7-2.6-2.7-3.8-1.3-1.6-2.5-5.2-2.4-14 .1-.9.2-1.8.4-2.9.2-1.2.5-2.4.9-3.6 18.3 6 24.5 7.2 25.6 6.4z"
        ></path>
        <path
          fill="#19642D"
          d="M-162.7 376c1.8.3 3.6 1 5.1 2.1 1.7 1.3 3 2.9 3.8 4.9-.1.7 0 1.4.3 2.1.5 1.4 1.5 2 2.5 3 1.1 1.2 1.9 2.6 2.4 4.1 1 2.5 1.3 5.2.9 7.8-.4 6.9 1.6 6.3 1.7 14.9 0 3.8-.4 4.7-.9 13.6-.2 3.5-.2 3.7-.4 4.6-.3 1.1-.5 2.3-.5 3.5 0 1.5.6 1.8.4 2.2-.4.7-6.1-.9-10.8-1.7-7.2-1.2-10.8-1.9-15.5-1.6-1.4.1-2.8.2-3.7-.6-1.6-1.5-.4-4.9.8-9.6 1-3.9 1.5-8 2.4-12 1.5-6.8 1.8-13.7.8-20.6 0 0 .8-10.1 4.1-12.6.5-.3.9-.9 1.1-1.5.3-1.1-.5-1.8-.2-2.4.7-1.3 5.2-.3 5.7-.2z"
        ></path>
        <path
          fill="#D89E94"
          d="M-153.5 441.6c.8-.1 1.7.2 2.3.6.2.1.1.1 1 1.3s.9 1.4.9 1.7c0 .4-.2.8-.4 1.1-.1.3-.3.6-.4.6-.2 0-.1-.2-.4-.5-.1-.1-.2-.3-.3-.4 0 0 .5.8 1.1 1.9.4.6.6 1.2.7 1.9.1.5 0 1-.4 1.3-.4.3-.9.3-1.3 0-.7-.5-.7-1.7-.9-1.7s-.1.2-.1.2c0 .3.2.4.4.8.1.2 0 .1.2.5.2.6.3.6.3.8-.1.4-.4.7-.8.8-.3.1-.7 0-1-.2-.8-.5-.9-1.7-1.1-1.7-.2 0-.1.2-.1.2 0 .2.1.4.3.5.2.3.3.6.3.9 0 .3-.1.6-.4.8-.3.2-.7.1-1.1-.1-.9-.5-.9-2.1-1.2-2-.1 0-.1.2-.2.2 0 .2 0 .3.1.5.2.3.3.5.4.8 0 .3-.1.6-.4.8-.4.2-.9.1-1.2-.2-.7-.4-.5-1.1-1-2.2-.4-.9-.6-.6-1.4-1.9-.6-.8-1-1.6-1.2-2.6-.2-.9-.1-1.8.2-2.6 1.5-2.1 5.5-2.1 7.1-2.1z"
        ></path>
        <path
          fill="#376E38"
          d="M-161.3 414.7v-.2c.1-1.1.8-6.3 1.1-8.5 0-.1.1-.6.2-1.2.5-4.4.2-4.4.7-7.3.8-4.5 1.6-4.6 1.7-7.2 0-1 .2-6.7-3.2-8.4-2.8-1.4-6.3.9-6.8 1.2-1.8 1.2-3.1 3.1-4.1 6.3-.3 1-.6 2.1-.8 3.1-1.1 7.2 2 12.4 1.8 21.5 0 .8-.1 1.6-.2 2.4v1.3c0 3 4.5 16.7 4.5 16.7 1.2 3.6 1.6 5.3 3 8.1.7 1.3 1.5 2.6 2.5 3.9 1.9 0 3.8-.5 5.4-1.5 1.5-.9 2.8-2.2 3.6-3.8-1.2-.9-2.2-2-3.1-3.3-1.2-1.7-1.8-3.1-3-6.8-3-8.3-3.8-12.5-3.3-16.3z"
        ></path>
        <path
          fill="#C18781"
          d="M-149.7 446.7l-.3.4-.1.1c-.1 0-.9-1.3-.8-1.4s.2.1.3.3c.3.4.3.7.6.7.1 0 .2-.1.3-.1z"
        ></path>
      </g>
    </svg>
  );
}

export default Seventh;

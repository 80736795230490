import React from "react";

function Third() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 459 918"
      xmlSpace="preserve"
    >
      <g id="cycle3">
        <path
          fill="#7C6A5C"
          d="M-144.1 524.4c.2.3.4.7.6 1 .2.5.4.9.5 1.4-.1.2-.1.3-.2.5-.6 1.1-1.5 1.9-2.7 2.4-1.6.9-3.3 1.7-5 2.2-1.8.6-3.7.8-5.6.5-.9-.1-.7-.2-2.7-.6-.5-.1-1.5-.3-5.2-.9-1.4-.1-2.8-.4-4.2-.7-2.9-.8-3.3-1.5-3.5-1.9-.3-.8-.2-1.6.2-2.4l27.8-1.5z"
        ></path>
        <path
          fill="#2B4421"
          d="M-156.6 518c.4.8 1 1.6 1.7 2.2.5.5 1.1.8 1.8 1.2.7.4 1.4.8 2.1 1 1.6.4 2.1-.4 4.3 0 .5.1 1.1.2 1.5.6.5.4.9 1 1 1.6.2 1.9-5 5-10.6 5.5-2.1.2-4.3 0-6.4-.4-3.7-.7-7.3-1.9-10.7-3.7l1.2-7.2 14.1-.8z"
        ></path>
        <path
          fill="#7C6A5C"
          d="M-178.1 518.6c-.1.3-.3.7-.5 1l-.9 1.2-.5.1c-1.2.1-2.4-.3-3.4-1-1.6-.8-3.1-1.9-4.5-3.1-1.4-1.2-2.6-2.7-3.4-4.4-.4-.9-.2-.7-.9-2.6-.1-.4-.5-1.5-2-4.8-.6-1.3-1.2-2.6-1.6-4-.9-2.9-.5-3.6-.3-3.9.5-.6 1.3-1 2.1-1.1l15.9 22.6z"
        ></path>
        <path
          fill="#2B4421"
          d="M-179.3 504.7c-.5.8-.8 1.7-.9 2.6-.1.7-.1 1.4-.1 2.1 0 .8.1 1.6.3 2.3.5 1.6 1.5 1.6 2.3 3.6.2.5.4 1 .3 1.6-.1.7-.4 1.3-.8 1.8-1.5 1.2-6.9-1.6-10.3-6.1-1.3-1.7-2.3-3.6-3-5.6-1.4-3.5-2.3-7.2-2.6-11l6.8-2.8 8 11.5z"
        ></path>
        <path
          fill="#1B2341"
          d="M-150.4 435.4c.1-.1.4-.3.6-.2.7.4-1.1 4.5-2.2 8.4-.4 1.6-.4 1.9-1.1 12.9-.6 8.2-.8 12.3-1.1 13-.8 2.2-2.1 4.6-1.1 6.7.5 1.1 1.6 1.8 1.9 3.7-.1.8-.2 1.7-.1 2.5.4 2.5-1.7 4.8-2.6 7.7-1.8 5.4 2.3 6.9.4 11.9-.7 1.8-2.2 4-1.7 6.2.2.8.3 1.6.4 2.4.2.8.2 1.7.2 2.6-.2 1-.5 1.5-.2 2 .2.4.5.4.8.8.4.9.5 1.9.2 2.8-.9 2.5-4.9 2.9-6.4 3-2.7.2-5.5-.4-7.9-1.7-1.2-4.5-.8-6.1-.1-6.6.3-.2 1-.5 1.1-1 .1-.5-.5-1.1-.6-1.3-1.7-1.7-.4-11.2 0-18.3.1-2.2.5-9.4 1.8-11.4.3-.5.5-1 .6-1.6.1-.9-.1-1.9-.5-2.7-.5-1.6 0-2-.2-4.6 0-.5-.1-1-.1-1.4-.1-1-.3-2-.6-2.9-.9-3.4-.4-9-2.7-14.1-.3-.6-1.1-1.1-1.2-2.1s.4-1.6.5-3c.1-1 0-1.9-.3-2.9-.7-3.6-1.2-7.3-1.4-11-1.5-.2-2.7-1.3-3.1-2.8-.3-1.3 0-2.6.9-3.6 18.4 6.2 24.7 7.4 25.8 6.6z"
        ></path>
        <path
          fill="#222955"
          d="M-161.6 426.2c.1-.2.3-.4.5-.4 1 0 1 3.4 3.3 6.3.9 1.1 1.7 1.3 2.7 2.2.4.4.5.9 1 1.1 1 .4 1.7-.5 2.4-.5 1.8.2 3.3 8 2.7 17.9-.2 3.3-.7 6.5-1.2 10-1 7.1-1.2 6.8-1.3 9.3.1 2.7 0 5.4-.4 8.1-.1 1-.3 2-.6 3-1.3 3.1-4.6 4.4-6.4 5-2.6 1-5.2 2.3-7.5 3.8-2.7 1.8-5.3 3.9-7.6 6.2-1.3 1-2.4 2.2-3.3 3.6-.8 1.4-.9 2.4-1.9 2.9-.9.3-2 .2-2.8-.3-2.6-1.1-3.8-3.5-5.3-6.5s-1.3-3.9-1-4.2c.5-.6 1.2-.8 2-.8.9-1 1.9-1.9 2.9-2.9 1.7-1.6 3.3-3 4.9-4.3 0 0 12.1-8.1 12.8-10.3 0-.1.1-.2.1-.2.1-.5.2-1 .1-1.4-.3-.9-.7-1.7-1.2-2.5-.7-1.3-.4-1.4-1.5-4.4-.2-.4-.3-.8-.5-1.3-.4-.9-.9-1.8-1.4-2.7-1.7-2.7-4.9-13.4-4.9-13.4-.6-1.9-1.4-4.6-2.2-8.2-.4-1.6-.5-3.3-.2-4.9.2-1.2.7-2.3 1.3-3.3 2.2-3.9 4.5-7.5 5.6-9.3 5.9 3.1 8.1 3.1 8.9 2.4z"
        ></path>
        <path
          fill="#D89E94"
          d="M-155.7 382c-3-3.3-7.6-4.6-11.9-3.4.6-1.2 1-2.5 1-3.9-.1-1.6-.5-3.3-1.1-4.8-.2-.5-.3-.9-.3-.9-1.3-3.5-1.3-13.6 4.1-16.3 5-2.4 12.2 2.7 13.2 5.1.4 1.3.8 2.5 1.4 3.7.2.5.4 1 .5 1.5.2.5.2 1.1.1 1.6s-.3.6-.4 1c-.1.6.3 1 .8 2s1.3 2.3.8 3c-.3.5-.9.2-1.2.8-.3.6.2 1.2-.3 1.7-.1.2-.2.2-.3.4-.1.2 0 .7-.1.9s-.2 0-.3.1-.3.4-.2 1.1c.1.7.1 1.4-.1 2-.5 1.4-3.3.7-4.8 2.4-.6.5-.9 1.2-.9 2z"
        ></path>
        <path
          fill="#220E0B"
          d="M-149.1 370.4c0-.1.3-.3.6-.2.1 0 .3 0 .3.2 0 .1 0 .2-.2.3-.1 0-.1 0-.4-.1-.1-.2-.3-.1-.3-.2z"
          opacity="0.35"
        ></path>
        <linearGradient
          id="SVGID_1_"
          x1="-5001.21"
          x2="-5003.751"
          y1="432.342"
          y2="432.342"
          gradientTransform="rotate(180 -2575.905 398)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#3C3C3B"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M-149 365.2c-.1-.3-.2-.7-.3-1-.1-.3-.3-.6-.5-.8-.4-.3-.8-.4-1.3-.4l.4-.7c.5-.2 1.1-.1 1.5.2.5.4.7 1 .6 1.7 0 .3-.2.7-.4 1z"
        ></path>
        <linearGradient
          id="SVGID_2_"
          x1="-5005.463"
          x2="-4992.053"
          y1="417.843"
          y2="432.463"
          gradientTransform="rotate(180 -2575.905 398)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#1D1D1B" stopOpacity="0.47"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M-166.6 374.7c.2 0-.1-.9.4-2.3s1.3-2.7 2.4-3.7c1.2-1.1 1.8-1 2.1-1.8.3-.8-.2-1.4.1-2.5.3-.9 1.1-1.6 2-1.8 1.4-.1 2.4 1.7 2.6 2.1.5.9.6 1.9.5 2.9l1.4-1.1c-.1-1.4.2-2.8.7-4.1.5-1.1 1-1.7.9-2.5-.2-1.2-1.5-1.6-1.3-2.1s1.3-.6 1.8-.6c1.1.1 2 .5 2.8 1.3.5-.2.9-.6 1.1-1.1.5-1.7-2.6-3.8-2.9-4-3.6-2.4-10.1-2.9-13.8.8-2 2-2.4 4.4-2.8 7.1-1.5 7.1 1.4 13.5 2 13.4z"
        ></path>
        <path
          fill="#995253"
          d="M-149 372.4c.1.1.2.3.1.4-.1.3-.5.4-.5.7 0 .1.1.2.1.3v.2c0 .2-.1.3-.2.4-.1 0-.2 0-.3-.1-.1 0-.2-.1-.4-.4s-.2-.4-.3-.6c-.2-.2-.3-.3-.3-.4 0-.4 1.4-1 1.8-.5z"
        ></path>
        <path
          fill="#6D5151"
          d="M-150.3 372.9c.3 0 .6 0 .8.3.1.1.2.2.2.4-.1-.1-.2-.2-.3-.4-.2-.2-.4-.3-.7-.3z"
        ></path>
        <path
          fill="#C18C86"
          d="M-151.1 364.8h.9c.1 0 .3.1.4.2.1.1.1.2.2.2s.2-.1.2-.2c0-.2-.6-.4-1-.4h-.8"
        ></path>
        <path
          fill="#C18C86"
          d="M-151.1 365c.2.1.4.3.5.5.1.2.2.3.3.5l.2.2v-.4c0-.2-.1-.4-.3-.5-.2-.3-.5-.5-.9-.7"
        ></path>
        <path
          fill="#EBEBEB"
          d="M-150.1 364.9v.3c0 .2.1.3.3.5 0 .1-.1.3-.2.4v.1c-.1-.5-.4-1-.8-1.4h.4l.3.1z"
        ></path>
        <path
          fill="#0F0F0F"
          d="M-150.1 364.9c.1 0 .3.1.4.2 0 .1-.1.2-.1.4l-.1.1c-.1-.1-.2-.2-.2-.4-.1-.1-.1-.2 0-.3z"
        ></path>
        <path
          fill="#220E0B"
          d="M-151.4 379.2c0-.1-2.3-.4-4.1-.8-2.4-.6-4.8-1.6-6.9-3 1.7 1.3 3.6 2.4 5.6 3.2.7.3 1.5.5 2.3.5.4 0 .6 0 1.2-.1.6.1 1.2.1 1.9.2z"
          opacity="0.16"
        ></path>
        <path
          fill="#220E0B"
          d="M-150.1 369.3c0 .5.2 1 .5 1.4.2.2.5.3.8.4-.3-.1-.5-.3-.7-.6-.3-.4-.5-.8-.6-1.2z"
          opacity="0.08"
        ></path>
        <path
          fill="#1D1D1B"
          d="M-150.7 357.2c.2.4.2.8.2 1.2-.3-.2-.6-.3-.9-.4-1.5-.6-3.2-.9-4.8-.7.4-.6.9-1 1.5-1.3 1.4-.8 3.2-.3 4 1.1-.1.1 0 .1 0 .1z"
          opacity="0.34"
        ></path>
        <path
          fill="#432918"
          d="M-159.9 363.9c-.1-.1-.6.3-.8.8-.1.9.3 1.7 1 2.2-.2-.5-.4-1.1-.4-1.6.1-.8.3-1.3.2-1.4z"
          opacity="0.15"
        ></path>
        <path
          fill="#19642D"
          d="M-162.8 376.5c1.8.3 3.6 1 5.1 2.1 1.7 1.3 3 2.9 3.8 4.9-.1.7 0 1.4.3 2.1.5 1.4 1.5 2 2.5 3 1.1 1.2 1.9 2.6 2.4 4.1 1.3 3 1 5.8.9 7.8-.4 7 1.6 6.3 1.7 14.9 0 3.9-.4 4.7-.9 13.6-.2 3.5-.2 3.7-.4 4.6-.3 1.1-.5 2.3-.5 3.4 0 1.5.6 1.8.4 2.2-.4.7-6.1-.9-10.8-1.7-7.2-1.2-10.8-1.8-15.5-1.6-1.4.1-2.8.2-3.7-.6-1.6-1.5-.4-4.9.8-9.6 1-3.9 1.5-8 2.4-12 1.5-6.8 1.8-13.7.8-20.6 0 0 .8-10.1 4.1-12.6.5-.3.9-.9 1.1-1.5.3-1.1-.5-1.8-.2-2.4.7-1.2 5.2-.2 5.7-.1z"
        ></path>
        <path
          fill="#D89E94"
          d="M-150.7 442.9c.8-.1 1.7.2 2.3.6.2.1.1.1 1 1.3s.9 1.4.9 1.7c0 .4-.2.8-.4 1.1-.1.3-.3.6-.4.6-.1 0-.1-.2-.4-.5-.1-.1-.2-.3-.3-.4 0 0 .5.8 1.1 1.9.4.6.6 1.2.7 1.9.1.5 0 1-.4 1.3-.4.3-.9.3-1.3 0-.7-.5-.7-1.7-.9-1.7s-.1.2-.1.2c-.1.3.2.4.4.9.1.2 0 .1.1.5.2.6.3.7.3.8-.1.4-.4.7-.8.8-.3.1-.7 0-1-.2-.8-.5-.9-1.7-1.1-1.7-.2 0-.1.2-.1.2 0 .2.1.4.3.6.2.3.3.6.3.9 0 .3-.1.6-.4.8-.3.2-.7.1-1.1-.1-.9-.5-.9-2.1-1.2-2-.1 0-.1.2-.2.2 0 .2 0 .3.1.5.2.3.3.5.4.8 0 .3-.1.6-.4.8-.4.2-.9.1-1.2-.2-.7-.4-.5-1.1-1-2.2-.4-.9-.6-.6-1.4-1.9-.6-.8-1-1.6-1.2-2.6-.2-.9-.1-1.8.2-2.6 1.6-2.3 5.5-2.3 7.2-2.3z"
        ></path>
        <path
          fill="#376E38"
          d="M-158.6 416v-.1c.1-1.1.8-6.3 1.1-8.5 0-.1.1-.6.2-1.2.5-4.3.2-4.4.7-7.3.8-4.5 1.6-4.5 1.7-7.2 0-1 .2-6.7-3.2-8.4-2.8-1.4-6.3.9-6.7 1.2-1.8 1.2-3.1 3.1-4.1 6.4-.3 1-.6 2.1-.8 3.1-1.1 7.2 2 12.4 1.8 21.5 0 .8-.1 1.6-.2 2.4 0 0-.1.6 0 1.3 0 3 4.5 16.7 4.5 16.7.7 2.8 1.7 5.5 3 8.1.7 1.3 1.5 2.6 2.4 3.9 3.8.1 7.3-1.9 9.1-5.2-1.2-.9-2.2-2-3.1-3.3-1.2-1.7-1.8-3.1-3-6.8-3-8.6-3.9-12.7-3.4-16.6z"
        ></path>
        <path
          fill="#C18781"
          d="M-147 448l-.3.4-.1.1c-.1 0-.9-1.3-.8-1.4.1-.1.2.1.3.3.3.4.3.7.6.7.1 0 .2-.1.3-.1z"
        ></path>
      </g>
    </svg>
  );
}

export default Third;

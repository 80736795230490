import React from "react";

export default function Laptop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="331.613"
      height="76.641"
      viewBox="0 0 331.613 76.641"
    >
      <g id="laptop-back" data-name="Group 186">
        <path
          fill="#F1F0F0"
          d="M14 0h272c7.7 0 14 6.3 14 14v136H0V14C0 6.3 6.3 0 14 0z"
          transform="scale(0.094 0.129)"
        ></path>

        <path
          fill="#D0D0D0"
          d="M1073.571-1402.75h-28.3v-2.017h28.3v2.017"
          transform="translate(-1045.3 1422.2)"
        ></path>
      </g>

      <g id="stickers" data-name="Group 185" transform="translate(12.472 7)">
        <g id="lamp">
          <g transform="translate(12.472 -13.449)">
            <path
              fill="#fff"
              d="M2.175 0A2.175 2.175 0 110 2.175 2.175 2.175 0 012.175 0z"
              data-name="Path 1010"
              transform="translate(-12.737 13.475)"
            ></path>
            <g data-name="Group 183">
              <g data-name="Group 182">
                <g data-name="Group 166">
                  <path
                    d="M-10.863 17.15a.294.294 0 00.1.3.325.325 0 00.251.1.343.343 0 00.251-.151v-.3z"
                    data-name="Path 992"
                  ></path>
                </g>
                <g data-name="Group 167">
                  <path
                    fill="#f4cf56"
                    d="M-11.014 16.748v.2s-.05.251.251.251h.453c.151-.05.2-.1.2-.251v-.2a.277.277 0 01.151-.251 1.144 1.144 0 00.352-1.408 1.322 1.322 0 00-.956-.5 1.026 1.026 0 00-.905.5 1.117 1.117 0 00.3 1.408.277.277 0 01.154.251z"
                    data-name="Path 993"
                  ></path>
                </g>
                <g data-name="Group 168">
                  <path
                    d="M-10.511 17.251h-.251a.384.384 0 01-.251-.1c-.05-.1-.05-.151 0-.2v-.2c-.05-.1-.05-.151-.151-.2a1.216 1.216 0 01-.352-1.459 1.14 1.14 0 01.956-.5 1.14 1.14 0 01.956.5 1.144 1.144 0 01-.354 1.408c-.1.1-.151.151-.151.251v.2c0 .151-.05.251-.2.251a.38.38 0 01-.202.049zm-.5-.3a.248.248 0 00.05.151.261.261 0 00.2.1h.4c.2-.05.2-.1.2-.251v-.2a.277.277 0 01.151-.251 1.074 1.074 0 00.352-1.358 1.121 1.121 0 00-.905-.553 1.072 1.072 0 00-.905.5 1.123 1.123 0 00.352 1.408.384.384 0 01.1.251v.201z"
                    data-name="Path 994"
                  ></path>
                </g>
                <g data-name="Group 169">
                  <path
                    fill="#f4cf56"
                    d="M-10.562 14.334a.049.049 0 00-.05-.05 1.686 1.686 0 010-.5c.05-.1.1.05.1.251s.001.349-.05.299z"
                    data-name="Path 995"
                  ></path>
                </g>
                <g data-name="Group 170">
                  <path
                    fill="#f4cf56"
                    d="M-11.718 14.987h-.05a3.649 3.649 0 01-.453-.251c-.05-.1.1-.05.3.05.152.051.303.151.203.201z"
                    data-name="Path 996"
                  ></path>
                </g>
                <g data-name="Group 171">
                  <path
                    fill="#e93839"
                    d="M-11.165 14.485s-.352-.553-.453-.553c-.05 0 0 .1.1.251s.3.4.352.4.051-.048.001-.098z"
                    data-name="Path 997"
                  ></path>
                </g>
                <g data-name="Group 172">
                  <path
                    fill="#e93839"
                    d="M-11.869 15.541h-.6c-.05.05.1.1.251.1a1.116 1.116 0 00.453-.05c-.004-.05-.004-.05-.104-.05z"
                    data-name="Path 998"
                  ></path>
                </g>
                <g data-name="Group 173">
                  <path
                    fill="#f4cf56"
                    d="M-9.4 14.987h.05c.1-.05.4-.151.453-.251s-.1-.05-.3.05c-.158.051-.308.151-.203.201z"
                    data-name="Path 999"
                  ></path>
                </g>
                <g data-name="Group 174">
                  <path
                    fill="#e93839"
                    d="M-9.958 14.485a3.668 3.668 0 01.453-.553c.05 0 0 .1-.1.251a.96.96 0 01-.352.4l-.001-.098z"
                    data-name="Path 1000"
                  ></path>
                </g>
                <g data-name="Group 175">
                  <path
                    fill="#e93839"
                    d="M-9.254 15.541h.6c.05.05-.1.1-.251.1a1.116 1.116 0 01-.453-.05c.003-.05.003-.05.104-.05z"
                    data-name="Path 1001"
                  ></path>
                </g>
                <g data-name="Group 176">
                  <path
                    d="M-10.562 17.2a5.283 5.283 0 00-.1-.654.85.85 0 00-.1-.251 1.184 1.184 0 01-.1-.4l.151.453c.05.1.05.151.1.251a2.658 2.658 0 01.05.6z"
                    data-name="Path 1002"
                  ></path>
                </g>
                <g data-name="Group 177">
                  <path
                    d="M-10.562 17.2a3.824 3.824 0 01.05-.7.85.85 0 01.1-.251l.151-.453a1.191 1.191 0 01-.151.5 2.178 2.178 0 01-.05.251 5.283 5.283 0 00-.1.654z"
                    data-name="Path 1003"
                  ></path>
                </g>
                <g data-name="Group 178">
                  <path
                    fill="#71cc55"
                    d="M-12.121 16.044a.151.151 0 010 .3c-.151.056-.201-.3 0-.3z"
                    data-name="Path 1004"
                  ></path>
                </g>
                <g data-name="Group 179">
                  <path
                    fill="#e93839"
                    d="M-11.417 16.7a.1.1 0 110 .2c-.1 0-.151-.2 0-.2z"
                    data-name="Path 1005"
                  ></path>
                </g>
                <g data-name="Group 180">
                  <path
                    fill="#f4cf56"
                    d="M-8.952 16.094a.1.1 0 110 .2c-.101.051-.101-.2 0-.2z"
                    data-name="Path 1006"
                  ></path>
                </g>
                <g data-name="Group 181">
                  <path
                    fill="#71cc55"
                    d="M-9.606 16.647a.151.151 0 110 .3c-.151.002-.151-.3 0-.3z"
                    data-name="Path 1007"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="js" transform="translate(-12 8)">
          <path
            fill="#ffd600"
            d="M-27 31v-2h2v2z"
            data-name="Path 1008"
            transform="translate(27 -29)"
          ></path>
          <path
            fill="#000001"
            d="M1.663 2.462c.031.07.064.138.134.138a.088.088 0 00.09-.1c0-.069-.032-.093-.1-.133l-.035-.022a.313.313 0 01-.171-.3c0-.15.081-.265.208-.265.091 0 .156.044.2.161l-.111.1c-.025-.061-.051-.086-.092-.086s-.068.038-.068.086.027.085.088.122l.036.021a.324.324 0 01.189.316c0 .181-.1.28-.236.28a.269.269 0 01-.249-.211zm-.511.005c.022.057.056.1.105.1s.073-.026.073-.128v-.661h.147v.693c0 .21-.086.307-.212.307s-.2-.109-.229-.211z"
            data-name="Path 1009"
            transform="translate(-.137 -.919)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

import React from "react";

function Fourth() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 459 918"
      xmlSpace="preserve"
    >
      <g id="cycle4">
        <image
          width="81"
          height="122"
          overflow="visible"
          transform="matrix(.24 0 0 .24 -169.93 390.49)"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAAB6CAYAAADH5L/DAAAACXBIWXMAAC4jAAAuIwF4pT92AAAB IUlEQVR4Xu3QoQEAIAzAsMH/P8MJCCoTXdU1M2f4sl8BbyYGTAyYGDAxYGLAxICJARMDJgZMDJgY MDFgYsDEgIkBEwMmBkwMmBgwMWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDE gIkBEwMmBkwMmBgwMWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDEgIkBEwMm BkwMmBgwMWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDEgIkBEwMmBkwMmBgw MWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDEgIkBEwMmBkwMmBgwMWBiwMSA iQETAxe7FAHzMhQMkgAAAABJRU5ErkJggg=="
        ></image>
        <g>
          <path
            fill="#C18781"
            d="M-171.4 450.1l-.4.2h-.1c-.1-.1-.2-1.6 0-1.6.1 0 .1.2.1.4.1.5 0 .8.1.9.1.1.2.1.3.1z"
          ></path>
          <path
            fill="#7C6A5C"
            d="M-154.2 525.7c.2.3.3.7.4 1 .2.5.3 1 .3 1.5-.1.2-.2.3-.3.5-.7 1-1.8 1.7-3 2-1.7.7-3.5 1.2-5.3 1.4-1.9.3-3.8.2-5.6-.3-.9-.3-.7-.3-2.6-1-.4-.2-1.5-.5-5-1.6-1.4-.4-2.7-.8-4-1.3-2.7-1.2-3.1-2-3.2-2.3-.1-.8.1-1.7.6-2.3l27.7 2.4z"
          ></path>
          <path
            fill="#2B4421"
            d="M-165.5 517.6c.3.9.8 1.7 1.4 2.4.5.5 1 1 1.6 1.4.6.5 1.3 1 2 1.3 1.5.6 2.2-.1 4.2.6.5.1 1 .4 1.4.8.5.5.7 1.1.8 1.8 0 1.9-5.7 4.2-11.3 3.9-2.1-.1-4.2-.6-6.2-1.3-3.6-1.2-7-3-10.1-5.2l2.3-7 13.9 1.3z"
          ></path>
          <path
            fill="#7C6A5C"
            d="M-114.9 515.7c.1.4.2.7.2 1.1v1.5l-.4.4c-.9.8-2.1 1.2-3.3 1.3-1.8.3-3.6.4-5.5.3-1.9-.1-3.7-.6-5.4-1.5-.8-.4-.6-.4-2.3-1.5-.4-.3-1.3-.8-4.5-2.6-1.3-.6-2.5-1.3-3.7-2.1-2.4-1.7-2.6-2.6-2.6-3 0-.8.4-1.6 1-2.1l26.5 8.2z"
          ></path>
          <path
            fill="#2B4421"
            d="M-124.3 505.4c.1.9.4 1.8.9 2.6.4.6.8 1.2 1.2 1.7.5.6 1 1.2 1.6 1.7 1.3.9 2.1.4 4 1.5.5.2.9.6 1.2 1.1.4.6.5 1.2.4 1.9-.4 1.9-6.4 2.9-11.9 1.5-2.1-.6-4-1.5-5.8-2.6-3.3-1.9-6.2-4.4-8.8-7.2l3.7-6.3 13.5 4.1z"
          ></path>
          <path
            fill="#1B2341"
            d="M-150.3 435c.2-.2.4-.2.6-.2.2 0 1.3.4 1.4 7 .1 5.2-.5 7.9-1.2 14.2-.9 8-1.4 12-1.4 13.3 0 1.9 0 5.8-1.4 8-.5.7-.8 1.5-1 2.3-.1.5-.2 1.1-.1 1.6.1 2.8-4.7 16.3-6 19.9-3 8.7-3.4 11.2-4.4 13.1-.5.9-.8 1.9-1 2.9-.2 1.4.2 1.8-.1 2.4-.9 1.7-5.3 1-6.1.9-3.4-.6-6.6-1.9-9.3-4 .9-.9 1.7-1.9 2.3-3 .6-.9 1.1-1.9 1.5-2.9 1.7-4 2.7-10.6 2.8-11.2 0 0 2.1-12.8 5.7-18l.1-.2c.2-.4.4-.9.5-1.4.1-.9-.1-1.9-.5-2.7-.5-1.6 0-2-.2-4.6 0-.5-.1-1-.2-1.4-.1-1-.3-2-.6-2.9-.9-3.4-.4-9-2.7-14.1-1.5-3.4-2.3-2.1-3.9-5.6-2.8-6-2.1-13.2-2.1-13.2.2-2.2.6-4.4 1.3-6.5 18.6 5.9 24.9 7.1 26 6.3z"
          ></path>
          <path
            fill="#D89E94"
            d="M-155.6 381.6c-1.4-1.5-3.1-2.6-5-3.3-2.2-.7-4.6-.8-6.9-.2.6-1.2 1-2.5 1-3.9-.1-1.6-.5-3.3-1.1-4.8-.2-.5-.3-.9-.3-.9-1.3-3.5-1.3-13.6 4.1-16.3 5-2.4 12.2 2.7 13.2 5.1.4 1.3.8 2.5 1.4 3.7.2.5.4 1 .5 1.5.2.5.2 1.1.1 1.6s-.3.6-.4 1c-.1.6.3 1 .8 2 .5.9 1.3 2.3.8 3-.3.5-.9.2-1.2.8-.3.6.1 1.1-.3 1.7-.1.1-.2.2-.3.4s0 .7-.1.9c-.1.2-.2 0-.3.1-.1.1-.3.3-.2 1.1.1.7.1 1.4-.1 2-.5 1.4-3.3.7-4.8 2.4-.6.5-.9 1.3-.9 2.1z"
          ></path>
          <path
            fill="#220E0B"
            d="M-149 370c0-.1.3-.3.6-.2.1 0 .3 0 .3.2 0 .1 0 .2-.1.3-.1 0-.2 0-.4-.1-.2-.2-.4-.2-.4-.2z"
            opacity="0.35"
          ></path>
          <linearGradient
            id="SVGID_1_"
            x1="-5309.21"
            x2="-5311.74"
            y1="432.735"
            y2="432.735"
            gradientTransform="rotate(180 -2729.86 398)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#3C3C3B"></stop>
            <stop offset="1"></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_1_)"
            d="M-148.9 364.8c-.1-.3-.2-.7-.3-1-.1-.3-.3-.6-.5-.8-.4-.3-.8-.4-1.3-.3l.4-.7c.5-.2 1-.1 1.5.2.5.4.7 1 .6 1.7 0 .2-.2.6-.4.9z"
          ></path>
          <linearGradient
            id="SVGID_2_"
            x1="-5313.433"
            x2="-5300.023"
            y1="418.234"
            y2="432.854"
            gradientTransform="rotate(180 -2729.86 398)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#1D1D1B" stopOpacity="0.47"></stop>
            <stop offset="1"></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_2_)"
            d="M-166.6 374.3c.2 0-.1-.9.4-2.3s1.3-2.7 2.4-3.7c1.2-1.1 1.8-1 2.1-1.8s-.3-1.4.1-2.5c.3-.9 1-1.6 2-1.8 1.4-.1 2.4 1.7 2.6 2.1.5.9.6 1.9.5 2.9l1.4-1.1c-.1-1.4.2-2.8.7-4.1.5-1.1 1-1.7.9-2.5-.3-1.2-1.5-1.6-1.3-2.1.2-.6 1.3-.6 1.8-.6 1 .1 2 .5 2.8 1.2.5-.2.9-.5 1.1-1 .5-1.7-2.6-3.8-3-4-3.6-2.4-10.1-2.9-13.8.8-2 2-2.4 4.4-2.8 7.1-1.3 7.1 1.6 13.5 2.1 13.4z"
          ></path>
          <path
            fill="#995253"
            d="M-148.9 372c.1.1.2.3.2.4-.1.3-.5.4-.5.7 0 .1.1.2.1.3v.2c0 .2-.1.3-.2.4-.1 0-.2 0-.3-.1-.2-.1-.3-.2-.4-.4-.3-.3-.2-.4-.3-.6-.2-.2-.3-.3-.3-.4-.2-.5 1.3-1 1.7-.5z"
          ></path>
          <path
            fill="#6D5151"
            d="M-150.2 372.5c.3 0 .6 0 .8.3.1.1.1.2.2.4-.1-.1-.2-.2-.3-.4-.2-.2-.4-.3-.7-.3z"
          ></path>
          <path
            fill="#C18C86"
            d="M-151 364.4c.3-.1.6-.1.9 0 .1 0 .3.1.4.1.1.1.1.2.2.2s.2-.1.2-.2-.6-.4-1-.4h-.8"
          ></path>
          <path
            fill="#C18C86"
            d="M-151 364.6c.2.1.4.3.5.5.1.2.2.3.3.5l.2.2v-.4c0-.2-.1-.4-.3-.5-.2-.3-.5-.6-.9-.7"
          ></path>
          <path
            fill="#EBEBEB"
            d="M-150.1 364.5v.2c0 .2.1.4.3.5-.1.1-.1.3-.2.4v.2c0-.2-.1-.3-.1-.5-.1-.3-.4-.6-.7-.8h.5c.1-.1.1-.1.2 0z"
          ></path>
          <path
            fill="#0F0F0F"
            d="M-150.1 364.5c.1 0 .4.1.4.2s-.1.2-.1.4v.1c-.1-.1-.2-.2-.2-.4-.1-.1-.1-.2-.1-.3z"
          ></path>
          <path
            fill="#220E0B"
            d="M-151.4 378.8c0-.1-2.3-.4-4.1-.8-2.4-.6-4.8-1.6-6.9-3 1.8 1.3 3.6 2.4 5.6 3.2.7.3 1.5.5 2.3.5.5 0 .6 0 1.2-.1.7 0 1.3.1 1.9.2z"
            opacity="0.16"
          ></path>
          <path
            fill="#220E0B"
            d="M-150 368.9c0 .5.2 1 .5 1.4.2.2.5.3.8.4-.3-.1-.5-.3-.7-.6-.3-.4-.5-.8-.6-1.2z"
            opacity="0.08"
          ></path>
          <path
            fill="#1D1D1B"
            d="M-150.6 356.8c.1.4.2.8.2 1.2-.3-.2-.6-.3-.9-.4-1.5-.6-3.2-.9-4.8-.7.4-.6.9-1.1 1.5-1.4 1.4-.8 3.2-.2 4 1.2-.1 0-.1.1 0 .1z"
            opacity="0.34"
          ></path>
          <path
            fill="#432918"
            d="M-159.8 363.5c-.1-.1-.6.3-.8.8-.1.9.3 1.7 1 2.2-.2-.5-.4-1.1-.4-1.6 0-.8.3-1.3.2-1.4z"
            opacity="0.15"
          ></path>
          <path
            fill="#222955"
            d="M-176.5 432.9c4-2.7 6.4-2.6 7.9-2.1s4.5 2.5 6.5 3c1.1.2 2.3.3 3.5.1.5 0 1 .1 1.4.2 3.2.9 6 2.6 8.4 4.9 1.2 1.4 2.3 2.9 3.3 4.5 5.8 9 6.3 10.8 9.6 14.6 1.1 1.3 1.7 1.9 3.2 3.7 1.8 2.1 3.3 4.3 4.6 6.7.5.9.9 1.7 1.3 2.7 1.1 2.9 1.7 6 1.8 9.2.4 10.4-2 11.8-.6 18.1.9 4.2 2.4 5.7 1.5 7-1.7 2.2-8.6-.1-14.5-2.7.5-.6.8-1.2 1.1-1.9.8-2 .3-3.7.2-5.2-.2-2.3-.1-4.6-.1-6.9-.2-8.1 0-7.9-.3-8.4-.8-1.6-4.2-7-4.3-7.2l-.1-.2c-.2-.4-.5-.9-.8-1.2-.7-.5-1.6-1-2.5-1.2-1.3-.6-1.1-.9-3.8-2.6-.4-.3-.7-.5-1.2-.7-.9-.5-1.8-.9-2.7-1.3-1.2-.5-6.3-4.1-19.9-14.7-.8-.7-1.5-1.4-2.2-2.2-4-5.3-2.4-12.1-2-13.9l.7-2.3z"
          ></path>
          <path
            fill="#D89E94"
            d="M-133.3 440.7c.8-.2 1.7 0 2.4.4.2.1.1.1 1.1 1.2s1.1 1.3 1.1 1.6c0 .4-.1.8-.2 1.1-.1.3-.2.6-.4.6s-.1-.2-.4-.5c-.3-.3-.3-.4-.4-.4 0 0 .6.8 1.3 1.8.4.5.8 1.2.9 1.8.1.3.2 1-.2 1.4-.4.3-.9.4-1.3.1-.8-.4-.9-1.7-1.1-1.6s-.1.2-.1.2c0 .3.2.4.5.8.1.2 0 .1.2.5.3.6.4.6.4.8 0 .4-.3.8-.7.9-.3.1-.7.1-1 0-.9-.4-1-1.6-1.2-1.6-.2.1-.1.2-.1.2 0 .2.2.3.3.5.2.2.4.5.4.9.1.5-.2.9-.6 1-.3.1-.5 0-.7-.1-1-.4-1.1-1.9-1.4-1.9-.1 0-.1.2-.1.3 0 .2.1.3.2.5.2.2.4.5.5.8.1.3 0 .6-.3.9-.4.2-.8.2-1.2 0-.7-.4-.6-1-1.2-2.1-.5-.8-.6-.6-1.6-1.8-.7-.7-1.2-1.5-1.5-2.4-.3-.9-.3-1.8-.1-2.7.9-2.6 4.8-3 6.5-3.2z"
          ></path>
          <path
            fill="#275427"
            d="M-146.1 412.1c-.2-.6-2-6-2.8-8.3 0-.1-.2-.6-.4-1.1-1.4-4.1-1.7-4-2.5-6.9-1.3-4.4-.6-4.8-1.6-7.2-.4-.9-2.8-6.1-6.6-6.2-3.1 0-5.2 3.6-5.5 4.1-1.1 1.9-1.4 4.1-1 7.5.1 1.1.4 2.1.7 3.1 2.2 7 7.3 10.3 11 18.6.8 1.8 1 2.5 2.8 6.8 1.5 3.4 2.3 5.2 3.1 6.7 1.3 2.6 1.3 2.2 2.5 4.4 2.6 5 4.3 8.7 4.9 10.1s1 2.4 1.2 3.2c2.6-.6 5-2.1 6.7-4.1.8-.9 1.4-2 1.9-3.1-.8-.9-1.8-2.2-3-3.8-2.9-4.1-5.5-8.4-7.5-13-1.5-3.5-2.8-7.1-3.9-10.8z"
          ></path>
          <path
            fill="#1D1D1B"
            d="M-132.5 441.2c-.2 1-.7 1.9-1.3 2.7-1.4 1.6-3.3 2.6-5.4 2.7l6.7-5.4z"
            opacity="0.42"
          ></path>
          <path
            fill="#C18781"
            d="M-129 445.4c-.1.1-.1.3-.2.4l-.1.1c-.1 0-1-1.2-.9-1.3.1-.1.2.1.3.2.4.3.4.6.6.7.1-.1.2-.1.3-.1z"
          ></path>
          <path
            fill="#19642D"
            d="M-162.7 376.1c1.8.3 3.6 1 5.1 2.1 1.7 1.2 3 2.9 3.8 4.9-.1.7 0 1.5.3 2.1.5 1.4 1.5 2 2.5 3 1.1 1.2 1.9 2.6 2.4 4.1 1 2.5 1.3 5.2.9 7.8-.4 7 1.6 6.3 1.7 14.9 0 3.9-.4 4.7-.9 13.6-.2 3.5-.2 3.7-.4 4.6-.3 1.1-.5 2.3-.5 3.4 0 1.5.6 1.8.4 2.2-.4.7-6.1-.9-10.8-1.7-7.2-1.2-10.8-1.8-15.5-1.6-1.4.1-2.8.2-3.7-.6-1.6-1.5-.4-4.9.8-9.6 1-3.9 1.5-8 2.4-11.9 1.5-6.8 1.8-13.7.8-20.6 0 0 .8-10.1 4.1-12.6.5-.3.9-.9 1.2-1.5.3-1.1-.5-1.8-.2-2.4.5-1.3 5.1-.3 5.6-.2z"
          ></path>
          <path
            fill="#000D00"
            d="M-167.4 392.5c-.6 2.4-.6 4.8 0 7.2.6 2.2 1.5 3.9 3.6 6.7 3.9 5.2 10 12 11 11.3.3-.2 0-1.2-.9-3.3-1.6-3.7-5.3-10.9-13.7-21.9z"
            opacity="0.22"
          ></path>
          <path
            fill="#D89E94"
            d="M-183.8 444c.6.5 1 1.3 1.2 2.1 0 .6-.1 1.1-.3 1.7-.2 1.4-.3 1.6-.6 1.9-.3.2-.6.4-1 .5-.3.1-.6.2-.8.1s0-.2.1-.6c0-.2.1-.3 0-.5 0 0-.3.9-.6 2.1-.1.7-.4 1.3-.9 1.9-.2.2-.7.7-1.2.7-.5-.1-.8-.5-.9-1-.2-.9.8-1.7.6-1.8-.2-.1-.2 0-.2.1-.2.1-.2.4-.4.9-.1.2-.1.1-.2.4-.3.6-.3.7-.4.8-.3.2-.8.2-1.1 0-.3-.2-.5-.5-.6-.8-.2-.9.6-1.8.5-1.9s-.2 0-.2.1c-.1.1-.2.3-.2.6-.1.3-.2.6-.4.9-.2.2-.5.3-.8.3-.4-.1-.6-.4-.7-.8-.3-1 .8-2.1.6-2.3-.2-.2-.2 0-.3.1-.1.1-.2.3-.3.4l-.3.9c-.2.2-.5.4-.8.3-.4-.1-.6-.6-.7-1-.1-.8.4-1.1.9-2.3.2-.8.3-1.6.4-2.3.1-.9.5-1.8 1-2.7.5-.8 1.2-1.3 2.1-1.7 2.7-1.1 5.4 1.7 6.5 2.9z"
          ></path>
          <path
            fill="#C18781"
            d="M-184.8 450.3l-.4.1h-.1c-.1-.1.3-1.5.5-1.5.2.1 0 .2 0 .4 0 .5-.3.7-.1.9-.1 0 0 .1.1.1z"
          ></path>
          <path
            fill="#376E38"
            d="M-171.1 414.3c.3-.6 3.1-5.6 4.3-7.6.1-.1.3-.5.6-1 2.1-3.8 1.8-4 3.4-6.5 2.4-3.9 3.2-3.6 4.3-6.1.4-.9 2.7-6.2.2-9-2.1-2.3-6.2-1.5-6.7-1.4-2.1.5-4 1.7-6.2 4.3-.7.8-1.3 1.7-1.9 2.6-3.7 6.3-2.8 12.3-6.4 20.6-.8 1.8-1.2 2.4-3.2 6.7-1.6 3.4-2.3 5.2-2.9 6.8-1 2.7-.7 2.5-1.6 4.8-2 5.3-3.6 9-4.3 10.4-.7 1.4-1.2 2.3-1.5 3 2.2 1.5 4.8 2.3 7.5 2.3 1.2 0 2.4-.3 3.6-.7.2-1.2.4-2.8.8-4.8 1.1-4.9 2.6-9.7 4.6-14.3 1.7-3.4 3.5-6.8 5.4-10.1z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Fourth;

function Mountains() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1920"
      height="1080"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 1920 1080"
      xmlSpace="preserve"
    >
      <g id="mountains">
        <path
          fill="#025A8A"
          d="M-804.6 1040.5c124.1-124 263.2-194.2 359.1-342.5 38.4-59.4 96.7-99.8 137-158 39.2-56.5 31-165.7 112.8-156.7 53.4 5.8 160.8 106.2 218.9 129.3 31 12.3 76.2 46.3 109.7 46.9 69.3 1.2 43.2-21 92-46.6C346.2 449.2 445 339 571.4 475.7c86.3 93.4 83.4 157.6 209.7 105.5 80.5-33.1 102.2-108.8 179.5-146.6 190.3-93 247.3 131.5 408.9 156.4 63.1 9.7 132.9-77.3 182.2-85.5 92.9-15.5 113.2-54.1 184.6 48.4 20.9 30 19 63.2 49.4 88.6 30.5 25.5 77.7 24.9 109.9 56.2 42.1 40.9 71.9 100.5 109.8 148.7 46.4 59.1 158.6 133.7 160.2 201.6-106.5-66.6-348.2-14.4-474.4-50.1-128.8-36.4-283-128.2-421.3-114.8-165 15.9-313 18.5-488.7 10-163.9-8-316.8 22.5-478.7 58.4-195 43.3-367.3 28.6-558.5-17.4-201-48.3-353 79.6-538.5 95.5"
        ></path>
        <path
          fill="#0F446E"
          d="M-814.6 1040.5c125.1-60.1 273.3-124.2 408.9-136.7 162.2-15 298.3 66.9 468.7 58.6 163.9-8 305.4-79.6 468.7-87.9 160-8.1 310.1 36.9 468.5 19.3 113-12.5 220.1-33.6 339.3-29.1 134.9 5.1 196 68.2 319.1 109.9 212.6 71.9 476.2-148.8 528.6 144.1-203.6-80.3-463.7 16.7-680.9-19.8-109.2-18.4-207.6-70.3-313.9-95.2-117.8-27.7-255.4-12.8-374.2-1.9-429.6 39.1-866.4 58.2-1303.7 58.2-96.4 0-231.1-22-319.1 9.8"
        ></path>
        <path
          fill="#0A2946"
          d="M2386 1133.8c-23.6-53.2-248.4-93.2-316.2-125.1-65.4-30.7-159.1-17.9-231.6-7.6-153.8 21.9-296.4 67.1-449 2.7-305.2-128.7-658.9-55.9-977.1-29.6-226.7 18.8-420.2 75.2-658.2 66.2-149.9-5.7-401-45.5-548.5-9.8-198 48-55.7 98.8 44.6 98.8 269.9 0 673.6-1.1 943.5-1.1 661.3.1 1531.2 5.5 2192.5 5.5"
        ></path>
        <path
          fill="#0A2946"
          fillRule="evenodd"
          d="M148.9 559.9c3.8 33 76.8 30.6 110.6 21.4-13.3 75.2-239.2 36.8-148 150.1-26.7-.3-53.8-.9-80.2.5 1.5 7.7 1.1 16.6 3.3 25.3-35.8 10.4-90.1-7.7-89.2 41-44.9 8.2-89.2 35.2-138.6 21.5-39.8-11-56.3-67.2-109.3-42.9 41.5 27.9 77.4 30.1 40.3 82.6 34.5 13.2 96.6 46.8 130.9 43.6-7 12.8-9.4 35.9-18.6 49.1-2.9 2.2-3 2.1-.5-.4 35.6-16.6 59.5-55.6 96.9-63.7 36-7.8 54.1 25.5 85.2 1.2 20.1 8.7 39.4 3.4 60.4 4.8-3-13.1 5.3-32.7 2.3-45.7 22.9-8.7 48.9-11.6 74.4-6.8 4.7 7.2 10.8 13.8 15.2 20 24.2-25.6 48.8-98.8 57.1-136.3 76.3 3 77.1-19.6 112.4-76.8 32.5-52.6 36.9-52.1 96-52.9 25.4-.3 31 37.7 57.7 7 20.5-23.5-9.4-38.6-9.4-56 .1-49.4 15.8-92.9-54.7-90.4 35.7-80.1-118.5-13.3-147.5 1.2-50.5 25.3-122.5 64-160.6 102.6"
          clipRule="evenodd"
        ></path>
        <path
          fill="#0A2946"
          fillRule="evenodd"
          d="M1014.8 416.2c-41 29.9-88.4 62.3-103.9 108.2 25 4.8 50.3-7.2 67.7-18.4 11.3 45.8-27.3 44.2-47.5 69-8.6 10.5-26.2 13.6-35.4 25.5-7.8 10.1 7.1 35.2-1.1 46.7-34.3 48.4-102.9 61.3-137.7 104.3 74 30 196-109.1 203.2-170 29.8-6.6 63.8.6 88.5 11.6-1.3-28.9-22.1-47.8-33-73 11.6-1 21.4-8.1 33-9.4-.1-25-1.5-39.4-24.3-55.2 9.7-6.6 17.7-20.2 26-25.7-6.3-15.6-14.4-11.4-28.6-13.7"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M1168.4 546.2c-2.5 14.4-55 37.7-54.3 68.4 1.1 46.8 55 25.5 82.4 53 59 59.4-69.7 66.8-111.2 95.9 34.7 14.7 77.9 27.6 105.8-4.3 34.4.9 64.4 14.1 88 39.5 11.8-88.8 206 76.7 258.1 88.6-23-97.5-191.2-139.9-270.4-171.7 31.2-33.6 72.2-32.5 117.5-33.9-33.8-39.2-138.6-1.5-140.1-59.4-23.5-3.2-59.4-22.9-69.7-28.2 13.3-.8 20.8-7.1 34.7-8 0-26-15.4-37-40.8-39.9"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M623.7 566.7c-47 45.7-1.4 77.9-13.4 123.3-16.2 61.1-45.7 25.3-96.9 28.6-2.3.2-93.6 23.8-93.9 24-35.6 25.4-22.5 82.2-65.7 111.8 58.2 9.7 50.7-37.1 83.1-60.5 38.7-28 57.6-17.1 103-14.7 118.5 6.3 94-56.4 98.8-149.8 30.4-6.5 62.5-2.5 81.5 19 7-52.7-78.1-61.3-110.5-81.6"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M1454.7 573.5c30.2-1.3 54.2-11.3 82.9-12.7-11.1 14-13.2 25.3-38.9 33.5 23.7-2.5 31.2 8.5 47.8 13.4-11.2 7.5-19 26.2-29.8 34.2 6.3 4.1 16.2 16.6 22.8 20.5-29.2 27.6-74 15.1-103.3 41.6 35.5 11.2 68.1-3.9 101.1 7.5-.9 22.5-2.3 44.6-4.2 66.7 1.8-3.4 8.8-16 10.9-19.4-.1 10.4 2.2 22.7 3.1 33.1 2.3-5.5 15-20.7 17.6-26.3 2.5 17.3 16.3 28.6 20.8 39.8 5-14.8 24.1-29.2 29.5-41.3 32.3 15.1 35.6 49.6 14.8 75.2 6.8 1.5 12.7.6 19.4 2-4.9 30.5-9.2 65.8 13.6 87.2-2.8-15-1.5-31.6-4.9-45.8-1.6-1.4 19.5-.4 19.3-.4 5 27.3 186 107.8 127.4 27.7 85.8-14.6 196.2 30.4 256.6 86.5-33.8-43.4-103.3-91.1-152.4-101.7-53.2-11.5-158.3 20-187.6-25.5 26.9-17.2 63.9-16.2 95.8-23.6-6.8-22.3-26.8-33.4-52.5-32.5 42-30 94.1 9.2 143.1 5.4-2.1-41.2-73.3-42.5-109.5-41-12.6-30.4-51.3-26.4-82.4-33.1.2 1.4 3.5-12.9 3.5-14.2-15.7-4.6-27.6-13.6-45.9-13.3 33.9-.1 68.4.7 101.9-1.4-14.7-6.7-25.9-16.7-47-20.2-9.8-19.2-29.8-27.4-54.3-26 2.7-1 3.8-2.9 3.4-5.7-11.5 1.8-28.4-2.9-40.4-2.6 14.7-41.6-40.6-49.7-75.4-46.7 2.8-1.9 20.9-7.5 24.9-8.5 0 .9 1.1-16.4-3.2-12.3 18.7-7.5 28.3-21.7 52.3-21.7-4.1-10-1.9-22.5-5.1-31.6 21.2.3 30.8-10.2 53.2 5 1-9.2 1.1-16.4.2-25.5-50.4-54.4-184.7 8.1-236.2 46.7"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M-242.2 388.8c6.6 48.2 47 98.7 47.9 141.4-14.6-15.1-30.4-24.2-41.2-43.4-13.1 30-33.7 60.8-48.9 91.1 2.7-12.2 3.3-24.5 6.1-36.5-19.2 44-53.4 74.5-60.2 121.2-4.4 30-4.6 97.9-2.9 136.1-13.4-23.5-14.9-63.5-13.8-94.7-4.7 6.8-20.1 19.1-25.2 26.1-1.6-5.5 1-14.9-2.8-19.3-45.4 46.8-39.9 109.4-95.3 154.5-1.6-2.9-1.9-5.9-.6-9-11.7 19.6-26.9 34.3-43 50 8.6-25.7 20.8-53.4 27.7-77.3-43.1 38.1-98 68.4-144.5 104.7.4-55.8 61.9-102.6 96.5-141.3 36.7-40.9 77-100.3 111.7-143.6 33-41.1 86.6-60.7 113.4-107.8 31.1-54.5 20.9-108.7 75.1-152.2"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
export default Mountains;

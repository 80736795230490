import React from "react";

function First() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 459 918"
      xmlSpace="preserve"
    >
      <g id="cycle1">
        <path
          fill="#D89E94"
          d="M-123.6 425.1c.4-.8 1-1.4 1.8-1.8.6-.1 1.2-.2 1.7-.2 1.4-.2 1.7-.2 2 0 .3.2.6.5.8.9.2.3.4.6.3.7s-.2 0-.7.1c-.2 0-.3 0-.5.1h2.3c.7-.1 1.4.1 2.1.3.3.1.9.4 1 1 0 .5-.2 1-.7 1.2-.8.4-1.9-.3-2-.1s.1.2.1.2c.2.2.5 0 .9.1.2 0 .1 0 .5.1.7.1.7.1.9.2.3.3.5.7.4 1.1-.1.3-.3.6-.6.8-.8.5-2-.1-2.1.1s.1.2.2.2h.6c.3 0 .7 0 1 .2.3.2.5.4.6.7 0 .4-.3.7-.6.9-1 .6-2.3-.2-2.4 0 0 .1.1.2.1.3.1.1.3.2.5.1.3 0 .6 0 .9.1.3.1.5.4.6.7 0 .5-.4.8-.8 1-.7.4-1.3-.1-2.5-.2-.8 0-1.6.1-2.5.3-1 .1-2 .1-2.9-.2-.9-.3-1.7-.8-2.3-1.6-1.6-2.3.4-5.8 1.3-7.3z"
        ></path>
        <path
          fill="#7C6A5C"
          d="M-173.4 527c.1.4.2.7.2 1.1v1.5l-.4.4c-.9.8-2.1 1.3-3.3 1.4-1.8.4-3.6.5-5.5.4-1.9-.1-3.7-.5-5.5-1.3-.8-.4-.6-.4-2.3-1.5-.4-.2-1.3-.8-4.6-2.5-1.3-.6-2.5-1.3-3.7-2-2.5-1.7-2.7-2.5-2.7-2.9 0-.8.4-1.6 1-2.2l26.8 7.6z"
        ></path>
        <path
          fill="#2B4421"
          d="M-183 517c.2.9.5 1.8.9 2.6.4.6.8 1.2 1.3 1.7.5.6 1 1.2 1.7 1.7 1.4.9 2.1.3 4 1.4.5.2.9.6 1.2 1 .4.6.5 1.2.5 1.9-.4 1.9-6.4 3.1-11.8 1.8-2.1-.5-4.1-1.3-5.9-2.4-3.3-1.8-6.4-4.2-9-7l3.5-6.4 13.6 3.7z"
        ></path>
        <path
          fill="#D89E94"
          d="M-185 449.3c.6.5 1 1.3 1.2 2.1 0 .6-.1 1.1-.3 1.7-.2 1.4-.3 1.6-.6 1.9-.3.2-.6.4-1 .5-.3.1-.7.2-.8.1-.1-.1 0-.2.1-.6v-.5s-.3.9-.6 2.1c-.1.7-.4 1.3-.9 1.9-.2.2-.6.7-1.2.6-.5-.1-.8-.5-.9-1-.2-.9.8-1.7.6-1.8-.1-.1-.2.1-.2.1-.2.1-.2.4-.4.9-.1.2-.1.1-.2.4-.3.6-.3.7-.4.8-.3.2-.8.2-1.1 0-.3-.2-.5-.5-.6-.8-.2-.9.6-1.8.5-1.9-.2-.1-.2 0-.2.1-.1.1-.2.3-.2.6-.1.3-.2.6-.4.9-.2.2-.5.3-.8.3-.4-.1-.6-.4-.7-.8-.3-1 .8-2.1.6-2.3-.2-.2-.2 0-.3 0-.1.1-.2.3-.3.4l-.3.9c-.2.2-.5.4-.8.3-.4-.1-.6-.6-.7-1-.1-.8.4-1.1.9-2.3.2-.8.3-1.6.4-2.3.2-.9.5-1.8 1-2.7.5-.8 1.2-1.3 2.1-1.7 2.6-.9 5.3 1.9 6.5 3.1z"
        ></path>
        <path
          fill="#C18781"
          d="M-186 455.5l-.4.1h-.1c-.1-.1.3-1.5.5-1.5.2.1 0 .2 0 .4 0 .5-.3.7-.1.9-.1.1 0 .1.1.1z"
        ></path>
        <path
          fill="#275427"
          d="M-170.3 421.4l.1-.1c.5-.9 3.1-5.5 4.3-7.5.1-.1.3-.5.6-1 2.1-3.8 1.8-4 3.4-6.5 2.4-3.9 3.2-3.6 4.3-6.1.4-.9 2.7-6.2.2-9-2.1-2.3-6.2-1.5-6.7-1.4-2.1.5-4 1.7-6.2 4.3-.7.8-1.3 1.7-1.9 2.6-2.6 4.3-3.4 8.4-4.2 11.3-1.2 3.8-2.7 7.4-4.6 10.9-2.6 5-3.8 6.2-5.1 8.5-.5 1-.9 2-1.3 3-.5 1.1-.9 2.4-1.9 5.4-.7 2.2-.9 2.7-1.4 4.5-.5 1.4-1.2 3.5-2.1 6.1 1.1.9 2.4 1.5 3.8 1.9 1.4.3 2.9.4 4.3.1.3-2.9.9-5.7 1.9-8.4 1.5-4.2 3.9-8 6.9-11.3 2-2.1 3.9-4.6 5.6-7.3z"
        ></path>
        <path
          fill="#7C6A5C"
          d="M-116.7 513.7l.9.6c.4.3.8.7 1.1 1.1v.5c-.1 1.2-.6 2.4-1.4 3.3-1 1.5-2.2 2.9-3.6 4.2-1.4 1.3-3 2.3-4.8 2.9-.9.3-.7.1-2.7.6-.4.1-1.5.4-5 1.5-1.3.5-2.7.9-4.1 1.2-2.9.6-3.7.1-4-.2-.6-.6-.9-1.4-.8-2.2l24.4-13.5z"
        ></path>
        <path
          fill="#2B4421"
          d="M-130.6 513.4c.8.5 1.6.9 2.5 1.2.7.2 1.4.3 2.1.3.8.1 1.6.1 2.4 0 1.6-.3 1.8-1.3 3.8-1.9.5-.2 1.1-.2 1.6-.1.7.1 1.2.5 1.7 1 1 1.6-2.4 6.7-7.2 9.6-1.8 1.1-3.8 1.9-5.9 2.4-3.7 1-7.5 1.5-11.3 1.4l-2-7 12.3-6.9z"
        ></path>
        <path
          fill="#1B2341"
          d="M-158.3 441c.1-.2.3-.4.5-.4 1-.2 1.4 3.1 4.1 5.5 1.6 1.5 2.3 1 4 2.6 1.4 1.3 2.5 3 3.1 4.8 1.6 3.5 2.3 7.3 2.2 11.2-.2 3.8-1.1 3.7-.7 6.1.6 3.1 2.4 4.7 1.9 7.4-.2.8-.2 1.6 0 2.3.2.8.5 1.6 1 2.2 1.4 2 .4 4.7.7 7.6.6 5.3 4.9 5 5.1 10.1.1 1.9-.4 4.3.9 6 .2.2.7.9 1.3 1.9.5.7.9 1.4 1.2 2.1.3.9.1 1.5.6 1.9.3.3.6.2 1 .4.8.5 1.3 1.4 1.3 2.4.3 2.7-11.8 6.3-12.5 6.5-2.9-3.4-3.2-4.9-2.7-5.6.2-.3.7-.8.6-1.3s-.9-.8-1-.9c-2.2-.8-4.8-9.4-7.3-15.8-.8-2-3.3-8.3-2.9-10.5.1-.5.1-1.1 0-1.6-.3-.8-.8-1.6-1.5-2.1-1.1-1.2-.8-1.7-2-3.9-.7-1.2-1.6-2.4-2.6-3.4-6.2-8.1-7.7-12.8-7.7-12.8-2.1-9.7 8.5-17.4 11.4-22.7z"
        ></path>
        <path
          fill="#222955"
          d="M-149.2 445.8c.2-.1.4-.1.7 0 .2.1 1.1.8-1 6.6-1.7 4.6-3.2 6.8-5.9 12.1-3.6 6.8-5.4 10.1-5.8 11.3-.7 1.6-2 5.1-4 6.6-.7.4-1.2 1-1.7 1.7s-.8 1.4-1 2.2c-.5 2.3-3.2 3.7-5.1 6-3.5 4.2-.2 6.8-3.7 10.6-1.3 1.4-3.5 2.9-3.8 5-.1.7-.2 1.5-.4 2.2-.1.8-.4 1.6-.7 2.3-.5.8-1 1.1-.9 1.7 0 .4.3.5.5.9.1.9-.2 1.8-.8 2.5-1.7 1.9-5.6 1-7 .6-2.6-.7-5-2.1-6.9-4 .4-4.3 1.3-5.6 2.2-5.8.4-.1 1.1-.1 1.4-.6.3-.5-.1-1.2-.1-1.3-1-2.1 3.4-10 6.3-16.2.9-1.9 3.7-8.1 5.6-9.5.5-.3.8-.7 1.1-1.2.4-.8.5-1.7.5-2.5.1-1.6.7-1.8 1.4-4.2.2-.5.3-.9.3-1.3.2-.9.4-1.9.4-2.8.3-3.3 2.7-8.1 2.2-13.3-.3-3.5-1.4-2.7-1.8-6.2-.5-6.1 2.6-12.3 2.6-12.3s21.7 10 25.4 8.9z"
        ></path>
        <path
          fill="#D89E94"
          d="M-155.6 388.8c-3-3.3-7.6-4.6-11.9-3.4.6-1.2 1-2.5 1-3.9-.1-1.6-.5-3.3-1.1-4.8-.2-.5-.3-.9-.3-.9-1.3-3.5-1.3-13.6 4.1-16.3 5-2.4 12.2 2.7 13.2 5.1.4 1.3.8 2.5 1.4 3.7.2.5.4 1 .5 1.5.2.5.2 1.1.1 1.6s-.3.6-.4 1c-.1.6.3 1 .8 2s1.3 2.3.8 3c-.3.5-.9.2-1.2.8s.1 1.2-.3 1.7c-.1.2-.2.2-.3.4-.1.2 0 .7-.1.9-.1.2-.2 0-.3.1s-.3.4-.2 1.1c.1.7.1 1.4-.1 2-.5 1.4-3.3.7-4.8 2.4-.6.4-.9 1.2-.9 2z"
        ></path>
        <path
          fill="#220E0B"
          d="M-149 377.1c0-.1.3-.3.6-.2.1 0 .3 0 .3.2 0 .1 0 .2-.1.3-.1 0-.2 0-.4-.1s-.4-.1-.4-.2z"
          opacity="0.35"
        ></path>
        <linearGradient
          id="SVGID_1_"
          x1="-4397.74"
          x2="-4400.27"
          y1="425.594"
          y2="425.594"
          gradientTransform="rotate(180 -2274.12 398)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#3C3C3B"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M-148.9 371.9c-.1-.3-.2-.7-.3-1-.1-.3-.3-.6-.5-.8-.4-.3-.8-.4-1.3-.4l.4-.7c.5-.2 1-.1 1.5.2.5.4.7 1 .6 1.7 0 .3-.2.7-.4 1z"
        ></path>
        <linearGradient
          id="SVGID_2_"
          x1="-4401.985"
          x2="-4388.575"
          y1="411.095"
          y2="425.715"
          gradientTransform="rotate(180 -2274.12 398)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#1D1D1B" stopOpacity="0.47"></stop>
          <stop offset="1"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M-166.5 381.5c.2 0-.1-.9.4-2.3s1.3-2.7 2.4-3.7c1.2-1.1 1.8-1 2.1-1.8.3-.8-.3-1.4.1-2.5.3-.9 1-1.6 2-1.8 1.4-.1 2.4 1.7 2.6 2.1.5.9.6 1.9.5 2.9l1.4-1.1c-.1-1.4.2-2.8.7-4.1.5-1.1 1-1.7.8-2.5-.3-1.2-1.5-1.6-1.3-2.1s1.3-.6 1.8-.6c1.1.1 2 .5 2.8 1.3.5-.2.9-.6 1.1-1.1.5-1.7-2.6-3.8-3-4-3.6-2.4-10.1-2.9-13.8.8-2 2-2.4 4.4-2.8 7.1-1.3 7.1 1.6 13.4 2.2 13.4z"
        ></path>
        <path
          fill="#995253"
          d="M-148.9 379.1c.1.1.2.3.1.4 0 .3-.5.4-.5.7 0 .1.1.2.1.3v.2c0 .2-.1.3-.2.4-.1 0-.2 0-.3-.1-.2-.1-.3-.2-.4-.4-.3-.3-.2-.4-.3-.6-.2-.2-.3-.3-.3-.4 0-.4 1.4-.9 1.8-.5z"
        ></path>
        <path
          fill="#6D5151"
          d="M-150.2 379.6c.3 0 .6 0 .8.3.1.1.1.2.2.4-.1-.1-.2-.2-.3-.4-.2-.2-.4-.3-.7-.3z"
        ></path>
        <path
          fill="#C18C86"
          d="M-151 371.5c.3-.1.6-.1.9 0 .1 0 .3.1.4.2.1.1.1.2.2.2s.2-.1.2-.2-.6-.4-1-.4h-.8M-151 371.8c.2.1.4.3.5.5.1.2.2.3.3.5l.2.2v-.4c-.1-.2-.1-.4-.3-.5-.2-.3-.5-.5-.9-.7"
        ></path>
        <path
          fill="#EBEBEB"
          d="M-150 371.6v.3c0 .2.1.3.3.5-.1.1-.1.3-.2.4l-.1.2c0-.2-.1-.3-.1-.5-.1-.3-.4-.6-.7-.8h.4l.4-.1z"
        ></path>
        <path
          fill="#0F0F0F"
          d="M-150 371.6c.1 0 .4.1.4.2s-.1.2-.1.4v.1c-.1-.1-.2-.2-.2-.4-.1-.1-.1-.2-.1-.3z"
        ></path>
        <path
          fill="#220E0B"
          d="M-151.3 385.9c0-.1-2.3-.4-4.1-.8-2.4-.6-4.8-1.6-6.9-3 1.8 1.3 3.6 2.4 5.6 3.2.7.3 1.5.5 2.3.5.4 0 .6 0 1.2-.1.6.1 1.2.2 1.9.2z"
          opacity="0.16"
        ></path>
        <path
          fill="#220E0B"
          d="M-150 376.1c0 .5.2 1 .5 1.4.2.2.5.3.8.4-.6-.5-1.1-1.1-1.3-1.8z"
          opacity="0.08"
        ></path>
        <path
          fill="#1D1D1B"
          d="M-150.6 364c.2.4.2.8.2 1.2-.3-.2-.6-.3-.9-.4-1.5-.6-3.2-.9-4.8-.7.4-.6.9-1.1 1.5-1.4 1.4-.8 3.2-.3 3.9 1.1 0 .1 0 .2.1.2z"
          opacity="0.34"
        ></path>
        <path
          fill="#432918"
          d="M-159.8 370.7c-.1-.1-.6.3-.8.8-.1.9.3 1.7 1 2.2-.2-.5-.4-1.1-.4-1.6.1-.9.4-1.4.2-1.4z"
          opacity="0.15"
        ></path>
        <path
          fill="#19642D"
          d="M-162.7 383.2c1.8.3 3.6 1 5.1 2.1 1.7 1.2 3 2.9 3.8 4.9-.1.7 0 1.5.3 2.1.5 1.4 1.5 2 2.5 3 1.1 1.2 1.9 2.6 2.4 4.1 1 2.5 1.3 5.2.9 7.8-.4 7 1.6 6.3 1.7 14.9 0 3.9-.4 4.7-.9 13.6-.2 3.5-.2 3.7-.4 4.6-.3 1.1-.5 2.3-.5 3.4 0 1.5.6 1.8.4 2.2-.6 1.2-5.4-.4-10.8-1.7-9.8-2.3-14-1.9-16.8-5.7-2.4-3.3 0-5.6.8-18.1.5-6.9.1-10.5.8-20.6 0 0 .8-10.1 4.1-12.6.5-.3.9-.9 1.2-1.5.3-1.1-.5-1.8-.2-2.4.6-1.2 5.1-.2 5.6-.1z"
        ></path>
        <path
          fill="#000D00"
          d="M-167.3 395.5c-.6 2.4-.6 4.8 0 7.2.6 2.2 1.5 4 3.6 6.7 3.9 5.2 10 12 11 11.3.3-.2 0-1.2-.9-3.3-1.7-3.7-5.4-10.9-13.7-21.9z"
          opacity="0.22"
        ></path>
        <path
          fill="#1D1D1B"
          d="M-122.9 425.1c.4.5.8 1 1 1.7 1 2.9-1.5 5.8-1.7 6l.7-7.7z"
          opacity="0.42"
        ></path>
        <path
          fill="#376E38"
          d="M-147.6 417.2c-1-1.4-.5-1.7-2.4-7.1-1.4-4.1-1.7-4-2.6-6.9-1.2-4.4-.5-4.8-1.6-7.2-.4-.9-2.8-6.1-6.6-6.2-3.1 0-5.2 3.6-5.5 4.1-1.1 1.9-1.4 4.1-1 7.5.8 5.8 3 7.8 11.7 21.8 1 1.9 2.5 3.5 4.3 4.7 1.9 1.2 2.8.9 7.3 2.1 6.2 1.5 6.6 2.5 10.4 2.8 1.2.1 2.4.1 4.8.3 2 .2 3.5.4 4.6.6.4-.6 2.6-3.6 1.7-7.1-.2-.8-.6-1.6-1.1-2.3-1.9.3-3.9.4-5.8.3-3.3-.3-5.5-1.1-9.5-2.6-5-2.1-7.5-3.1-8.7-4.8z"
        ></path>
        <path
          fill="#C18781"
          d="M-117.1 424.3l.2.4v.1c-.1.1-1.6.1-1.6 0s.2-.1.4-.1c.5-.1.8 0 .9-.1.1-.1.1-.2.1-.3z"
        ></path>
      </g>
    </svg>
  );
}

export default First;

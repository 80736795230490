import React from "react";

function Sixth() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 459 918"
      xmlSpace="preserve"
    >
      <g id="cycle6">
        <image
          width="81"
          height="123"
          overflow="visible"
          transform="matrix(.24 0 0 .24 -170.03 394.99)"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAAB7CAYAAAAMuGxmAAAACXBIWXMAAC4jAAAuIwF4pT92AAAB I0lEQVR4Xu3QoQEAIAzAsMH/P8MJCCoTXdU1M2f4sl8BbyYGTAyYGDAxYGLAxICJARMDJgZMDJgY MDFgYsDEgIkBEwMmBkwMmBgwMWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDE gIkBEwMmBkwMmBgwMWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDEgIkBEwMm BkwMmBgwMWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDEgIkBEwMmBkwMmBgw MWBiwMSAiQETAyYGTAyYGDAxYGLAxICJARMDJgZMDJgYMDFgYsDEgIkBEwMmBkwMmBgwMWBiwMSA iQETAyYGLjdKAfWDyt8CAAAAAElFTkSuQmCC"
        ></image>
        <g>
          <path
            fill="#D89E94"
            d="M-128.1 437.7c.7-.4 1.6-.6 2.4-.5.5.2 1 .5 1.5.8 1.2.6 1.4.8 1.6 1.1.1.4.2.8.2 1.1 0 .3 0 .7-.1.7s-.2-.1-.6-.3c-.1-.1-.3-.2-.5-.2 0 0 .8.5 1.8 1.3.6.4 1.1.8 1.5 1.4.3.4.4.9.3 1.3-.3.4-.7.6-1.2.6-.9-.1-1.4-1.3-1.6-1.1s0 .2 0 .2c.1.3.3.3.7.6.1.1.1.1.3.4.5.5.6.4.6.6.1.4 0 .8-.3 1.1-.3.2-.6.3-.9.3-.9-.1-1.5-1.2-1.7-1s0 .2 0 .3.3.3.5.4c.3.2.5.4.7.7.1.3.1.6 0 .9-.2.3-.6.5-1 .4-1.1-.1-1.7-1.5-1.9-1.3-.1 0-.1.2 0 .3.1.2.2.3.3.4.3.2.5.4.7.6.2.3.2.6 0 .9-.3.3-.7.5-1.2.3-.8-.1-.9-.8-1.9-1.5-.7-.6-.8-.3-2.1-1.1-.9-.4-1.6-1-2.2-1.8-.6-.7-.9-1.6-.9-2.5-.1-3 3.5-4.7 5-5.4z"
          ></path>
          <path
            fill="#7C6A5C"
            d="M-162.5 528.6c0 .4-.1.7-.1 1.1-.1.5-.2 1-.4 1.5-.1.1-.3.2-.5.3-1.1.5-2.4.6-3.6.3-1.8-.2-3.6-.6-5.3-1.2-1.8-.6-3.4-1.6-4.8-2.9-.7-.6-.5-.6-1.8-2.1-.3-.3-1-1.2-3.7-3.7-1.1-1-2.1-2-3-3-1.9-2.3-1.8-3.2-1.7-3.6.3-.8.8-1.4 1.6-1.8l23.3 15.1z"
          ></path>
          <path
            fill="#2B4421"
            d="M-168.7 516.2c-.1.9-.1 1.9.1 2.8.2.7.4 1.4.7 2 .3.7.6 1.4 1.1 2.1 1 1.3 1.9 1 3.4 2.5.4.4.7.8.9 1.3.2.6.2 1.3-.1 2-.9 1.7-7 1-11.8-1.8-1.8-1.1-3.5-2.5-4.9-4.1-2.6-2.8-4.8-5.9-6.5-9.3l5.3-5.1 11.8 7.6z"
          ></path>
          <path
            fill="#7C6A5C"
            d="M-120.3 520.6c.3.3.5.6.7.9.3.4.6.9.8 1.4 0 .1-.1.3-.1.5-.4 1.1-1.2 2.1-2.3 2.7-1.4 1.1-3 2.1-4.6 2.9-1.7.8-3.6 1.3-5.5 1.4-.9 0-.7-.1-2.7-.2-.5 0-1.6-.1-5.2-.1-1.4.1-2.8 0-4.3-.1-3-.3-3.5-1-3.7-1.3-.4-.7-.4-1.6-.1-2.4l27-5.7z"
          ></path>
          <path
            fill="#2B4421"
            d="M-133.4 516.1c.5.7 1.2 1.4 2 1.9.6.4 1.2.7 1.9.9.7.3 1.5.6 2.3.7 1.6.2 2-.7 4.2-.7.5 0 1.1.1 1.6.3.6.3 1 .8 1.3 1.5.5 1.9-4.3 5.7-9.7 7-2.1.5-4.2.7-6.4.5-3.8-.1-7.6-.8-11.2-2.1l.1-7.3 13.9-2.7z"
          ></path>
          <path
            fill="#1B2341"
            d="M-150.4 439.6c.2-.2.4-.2.6-.2.2 0 1.3.4 1.4 7 .1 5.2-.5 7.9-1.2 14.2-.9 8-1.4 12-1.4 13.3 0 1.8 0 5.8-1.4 7.9-.8 1.1-1.2 2.5-1.2 3.9.1 2.6-8.3 13.1-11.8 21.9-2 5-1.9 8.3-4.3 9-2.3.7-4.9-1.5-6.5-2.5-2.1-1.4-3.8-3.4-4.7-5.8l4.3-5.8c5.8-11.4 8.4-16.7 8.5-16.8l.1-.2c.2-.4.4-.9.5-1.3.1-.9-.1-1.9-.5-2.7-.5-1.6 0-2-.2-4.6 0-.5-.1-1-.1-1.4-.1-1-.3-2-.6-2.9-.9-3.4-.4-9-2.7-14.1-1.5-3.4-2.3-2.1-3.9-5.6-2.8-6-2.1-13.2-2.1-13.2.2-2.2.6-4.4 1.3-6.5 18.6 6 24.9 7.2 25.9 6.4z"
          ></path>
          <path
            fill="#222955"
            d="M-153.3 441.2c1.3.7 2.4 1.5 3.4 2.4 2.2 2.3 4 5.5 5.7 15 1.3 7 .8 8.1 2.2 16.1.3 1.5.4 2.9.5 4.4.3 4.4-.2 5.3-.3 8.1-.1 5.7 4.2 6.6 3.8 11.9-.2 1.9-1 4.3.2 6.5.4.8.8 1.5 1.1 2.3.4.8.7 1.7.9 2.5.1 1-.1 1.5.4 2.1.3.4.6.4 1 .7l1.5 2.6c-.9.9-1.9 1.6-3 2.2-3.6 1.9-7.8 2.6-11.8 1.8-1.4-4.9-1.4-6.7-1-7.3.3-.4.4-.9.3-1.3-.1-.4-.2-.7-.5-1-1.2-1.4-3.6-11-5.3-18.4-.5-2.3-2.3-9.5-1.6-11.7.2-.5.2-1.1.2-1.7-.2-1-.6-1.9-1.3-2.6-.2-.3-.4-.7-.5-1-1.2-2.3-13.1-13.9-16.9-19.7-2-3-3.9-7.8-3.4-15.5 4.6-1.1 16-3 24.4 1.6z"
          ></path>
          <path
            fill="#D89E94"
            d="M-155.7 386.2c-1.4-1.5-3.1-2.6-5-3.3-2.2-.7-4.6-.8-6.9-.2.6-1.2 1-2.5 1-3.9-.1-1.6-.5-3.3-1.1-4.8-.2-.5-.3-.9-.3-1-1.3-3.5-1.3-13.6 4.1-16.3 5-2.4 12.2 2.7 13.2 5.1.4 1.3.8 2.5 1.4 3.7.2.5.4 1 .5 1.5.1.5.2 1.1.1 1.6-.1.6-.3.6-.4 1-.1.7.3 1.1.8 2s1.3 2.3.9 3c-.3.5-.9.2-1.2.8-.3.6.1 1.1-.3 1.7-.1.2-.2.2-.3.4-.1.2 0 .7-.1.9-.1.2-.2 0-.3.1s-.3.3-.2 1.1c.1.7.1 1.4-.1 2-.5 1.4-3.3.7-4.8 2.4-.6.6-.9 1.4-1 2.2z"
          ></path>
          <path
            fill="#220E0B"
            d="M-149.1 374.6c0-.1.3-.3.6-.2.1 0 .3 0 .3.2 0 .1 0 .2-.1.3-.1 0-.1 0-.4-.1s-.3-.2-.4-.2z"
            opacity="0.35"
          ></path>
          <linearGradient
            id="SVGID_1_"
            x1="-5926.53"
            x2="-5929.071"
            y1="428.155"
            y2="428.155"
            gradientTransform="rotate(180 -3038.545 398)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#3C3C3B"></stop>
            <stop offset="1"></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_1_)"
            d="M-148.9 369.4c-.1-.3-.2-.7-.3-1-.1-.3-.3-.6-.5-.8-.4-.3-.8-.4-1.3-.4l.4-.7c.5-.2 1-.1 1.5.2.5.4.7 1 .6 1.7-.1.3-.2.6-.4 1z"
          ></path>
          <linearGradient
            id="SVGID_2_"
            x1="-5930.779"
            x2="-5917.37"
            y1="413.659"
            y2="428.269"
            gradientTransform="rotate(180 -3038.545 398)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#1D1D1B" stopOpacity="0.47"></stop>
            <stop offset="1"></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_2_)"
            d="M-166.6 378.9c.2 0-.1-.9.4-2.3s1.3-2.7 2.4-3.7c1.2-1.1 1.8-.9 2.1-1.8s-.3-1.4.1-2.5c.3-.9 1-1.6 2-1.8 1.4-.1 2.4 1.7 2.6 2.1.5.9.6 1.9.5 2.9l1.4-1.1c-.1-1.4.2-2.8.7-4.1.5-1.1 1-1.7.8-2.6-.2-1.2-1.5-1.5-1.3-2.1.2-.6 1.3-.6 1.8-.6 1 .1 2 .5 2.8 1.3.5-.2.9-.6 1.1-1.1.5-1.7-2.6-3.8-3-4-3.6-2.4-10.1-2.9-13.8.8-2 2-2.4 4.4-2.8 7.1-1.3 7.2 1.7 13.6 2.2 13.5z"
          ></path>
          <path
            fill="#995253"
            d="M-148.9 376.6c.1.1.2.3.1.4-.1.3-.5.5-.5.7 0 .1.1.2.1.3v.2c0 .1 0 .3-.1.3-.1.1-.3 0-.4 0s-.2-.1-.4-.4c-.2-.3-.2-.4-.3-.6s-.3-.3-.3-.4c-.1-.5 1.3-1 1.8-.5z"
          ></path>
          <path
            fill="#6D5151"
            d="M-150.2 377.1c.3 0 .6.1.8.3.1.1.2.2.2.4-.1-.1-.2-.3-.3-.4-.2-.2-.5-.3-.7-.3z"
          ></path>
          <path
            fill="#C18C86"
            d="M-151.1 369h.9c.1 0 .3.1.4.2.1.1.1.2.2.2s.2-.1.2-.2c0-.2-.6-.4-1-.4h-.8"
          ></path>
          <path
            fill="#C18C86"
            d="M-151.1 369.2c.2.1.4.3.5.5.1.2.2.3.3.4l.2.2c0-.3-.1-.6-.3-.9-.2-.3-.5-.6-.9-.7"
          ></path>
          <path
            fill="#EBEBEB"
            d="M-150.1 369v.3c0 .2.1.3.3.5-.1.1-.1.3-.2.4v.2c0-.2-.1-.3-.1-.5-.1-.3-.4-.6-.7-.8h.5l.2-.1z"
          ></path>
          <path
            fill="#0F0F0F"
            d="M-150.1 369c.1 0 .4.1.4.2s-.1.2-.1.4v.1c-.1-.1-.2-.2-.2-.4-.1-.1-.1-.2-.1-.3z"
          ></path>
          <path
            fill="#220E0B"
            d="M-151.4 383.4c0-.1-2.3-.4-4.1-.8-2.4-.6-4.8-1.6-6.9-3 1.7 1.3 3.6 2.4 5.6 3.2.7.3 1.5.5 2.3.5.4 0 .6-.1 1.2-.1 1.1 0 1.9.2 1.9.2z"
            opacity="0.16"
          ></path>
          <path
            fill="#220E0B"
            d="M-150.1 373.5c0 .5.2 1 .5 1.4.2.2.5.3.8.4-.3-.1-.5-.3-.7-.6-.3-.4-.5-.8-.6-1.2z"
            opacity="0.08"
          ></path>
          <path
            fill="#1D1D1B"
            d="M-150.7 361.4c.2.4.2.8.2 1.2-.3-.2-.6-.3-.9-.4-1.5-.6-3.2-.9-4.8-.7.4-.6.9-1.1 1.5-1.4 1.4-.8 3.2-.2 4 1.2v.1z"
            opacity="0.34"
          ></path>
          <path
            fill="#432918"
            d="M-159.9 368.1c-.1-.1-.6.3-.8.8-.1.9.3 1.7 1 2.2-.2-.5-.4-1.1-.4-1.6.1-.8.4-1.3.2-1.4z"
            opacity="0.15"
          ></path>
          <path
            fill="#275427"
            d="M-146.9 416.9v-.1c-.4-1-2-6-2.7-8.2l-.4-1.1c-1.4-4.1-1.7-4-2.5-6.9-1.3-4.4-.5-4.8-1.6-7.2-.4-.9-2.8-6.1-6.6-6.2-3.1 0-5.3 3.6-5.5 4.1-1.1 1.9-1.4 4.1-1 7.5.1 1.1.4 2.1.7 3.1 2.2 7 7.3 10.3 11 18.6.6 1.4.7 1.6.9 2.2 0 0 .2.6.5 1.2 1.3 2.7 11.3 13.1 11.3 13.1 1.9 2.2 4 4.2 6.3 5.9 1.2.9 2.5 1.7 3.8 2.5 3.4-1.5 5.7-4.9 5.9-8.7-1.5-.3-2.9-.9-4.2-1.6-1.8-1-3-2-5.7-4.8-6.4-6.3-9-9.7-10.2-13.4z"
          ></path>
          <path
            fill="#19642D"
            d="M-162.8 380.7c1.8.3 3.6 1 5.1 2.1 1.7 1.3 3 2.9 3.8 4.9-.1.7 0 1.4.3 2.1.5 1.4 1.5 2 2.5 3 1.1 1.2 1.9 2.6 2.4 4.1 1.3 3 1 5.8.9 7.8-.4 7 1.6 6.3 1.7 14.9 0 3.9-.4 4.7-.9 13.6-.2 3.5-.2 3.7-.4 4.6-.3 1.1-.5 2.3-.5 3.5 0 1.5.6 1.8.4 2.2-.4.7-6.1-.9-10.8-1.7-7.2-1.2-10.8-1.9-15.5-1.6-1.4.1-2.8.2-3.7-.6-1.6-1.5-.4-4.9.8-9.6 1-3.9 1.5-8 2.4-12 1.5-6.8 1.8-13.7.8-20.6 0 0 .8-10.1 4.1-12.6.5-.4.9-.9 1.1-1.5.3-1.1-.5-1.8-.2-2.4.7-1.3 5.2-.4 5.7-.2z"
          ></path>
          <path
            fill="#000D00"
            d="M-167.4 397.1c-.6 2.4-.6 4.8 0 7.2.6 2.2 1.5 3.9 3.6 6.7 3.9 5.2 10 12 11 11.2.3-.2 0-1.2-.9-3.3-1.6-3.6-5.3-10.8-13.7-21.8z"
            opacity="0.22"
          ></path>
          <path
            fill="#1D1D1B"
            d="M-127.8 437.7c.1 1 0 2-.3 3-.7 2-2.2 3.5-4.2 4.4l4.5-7.4z"
            opacity="0.42"
          ></path>
          <path
            fill="#C18781"
            d="M-122.5 440.6s0 .2-.1.4c0 0 0 .1-.1.1s-1.4-.8-1.3-.9.2 0 .4.1c.5.2.6.5.8.4.2.1.2 0 .3-.1z"
          ></path>
          <path
            fill="#D89E94"
            d="M-166.9 448.5c.8.1 1.6.6 2.1 1.2.3.5.5 1 .6 1.6.5 1.3.6 1.6.5 1.9-.1.4-.4.7-.6 1-.2.2-.4.5-.6.5s-.1-.2-.2-.6c0-.2-.1-.3-.2-.5 0 0 .3.9.5 2.1.2.6.3 1.3.2 2-.1.3-.2 1-.7 1.2-.5.1-1 0-1.3-.4-.6-.7-.2-1.9-.4-1.9s-.2.2-.2.2c-.1.2.1.4.1.9v.5c0 .7.1.7.1.9-.2.4-.6.6-1 .6-.3 0-.7-.1-.9-.4-.7-.7-.4-1.9-.6-1.9-.2 0-.1.1-.2.2 0 .1 0 .4.1.6.1.3.2.7.1 1 0 .1-.2.6-.6.7-.4.1-.8-.1-1-.3-.8-.8-.4-2.2-.6-2.3-.1 0-.2.1-.2.2-.1.2-.1.3 0 .5.1.3.1.6.2.9 0 .3-.3.6-.6.7-.4.1-.9-.2-1.1-.5-.5-.6-.2-1.2-.4-2.4-.2-.9-.4-.8-.8-2.2-.4-.9-.5-1.8-.5-2.8 0-.9.4-1.8.9-2.5 1.9-2.1 5.7-1.1 7.3-.7z"
          ></path>
          <path
            fill="#376E38"
            d="M-167.6 420.5v-.1c.4-1 2.4-5.9 3.3-8 .1-.1.2-.5.5-1.1 1.6-4.1 1.3-4.2 2.6-6.9 1.9-4.1 2.7-4 3.5-6.5.3-1 1.9-6.5-1-9-2.4-2.1-6.3-.7-6.8-.5-2 .7-3.8 2.2-5.6 5.1-.6.9-1.1 1.8-1.5 2.8-2.9 6.7-1.2 12.6-3.8 21.3-.2.8-.5 1.5-.8 2.2 0 0-.2.6-.4 1.3-.8 2.9.1 17.3.1 17.3 0 2.9.3 5.8.9 8.6.3 1.5.8 2.9 1.3 4.3 3.6 1.1 7.5.1 10.1-2.7-.9-1.2-1.6-2.6-2.2-4-.8-1.9-1-3.5-1.2-7.4-.7-8.9-.4-13.1 1-16.7z"
          ></path>
          <path
            fill="#C18781"
            d="M-164.6 454.4l-.3.3s-.1 0-.1.1c-.1-.1-.5-1.5-.4-1.5s.1.2.2.3c.2.5.2.7.4.9.1 0 .2 0 .2-.1z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Sixth;

function Sky() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1920"
      height="1080"
      viewBox="0 0 1920 1080"
    >
      <rect id="sky" width="100%" height="100%" />
    </svg>
  );
}

export default Sky;

function Tools() {
  return (
    <svg>
      <g transform="translate(36.008 10.262) translate(0 8.682)">
        <path
          fill="#ffcb62"
          d="M0 0H52.61V32.501H0z"
          transform="translate(.336 .204)"
        ></path>
        <path
          fill="#4f3460"
          d="M121.454 352.909H70.828a1.223 1.223 0 01-1.328-1.073v-30.763A1.224 1.224 0 0170.828 320h50.626a1.223 1.223 0 011.328 1.073v30.764a1.224 1.224 0 01-1.328 1.072zm-50.626-32.366a.6.6 0 00-.656.53v30.764a.6.6 0 00.656.529h50.626a.6.6 0 00.656-.529v-30.764a.6.6 0 00-.656-.53z"
          transform="translate(-69.5 -320)"
        ></path>
        <path
          fill="#fee2c5"
          d="M0 0H48.035V28.68H0z"
          transform="translate(2.686 3.212)"
        ></path>
        <path
          fill="#d4bda7"
          d="M0 0H48.072V1.499H0z"
          transform="translate(2.605 3.162)"
        ></path>
        <path
          fill="#e2cab1"
          d="M0 0H2.135V28.584H0z"
          transform="translate(2.605 3.162)"
        ></path>
        <path
          fill="#d4bda7"
          d="M0 0H2.135V28.584H0z"
          transform="translate(2.605 3.162)"
        ></path>
        <path
          fill="#4f3460"
          d="M131.744 377.992H83V349h48.744zm-48.072-.568h47.4v-27.856h-47.4z"
          transform="translate(-80.731 -346.042)"
        ></path>
        <path
          fill="#f27293"
          d="M316.047 502.471v1.734l-2.168.143a24.086 24.086 0 01-3.21 0l-2.168-.143v-1.734l2.168-.143a24.086 24.086 0 013.21 0z"
          transform="translate(-268.328 -476.95)"
        ></path>
        <path
          fill="#4f3460"
          d="M311.117 503.2c-.538 0-1.092-.02-1.63-.051l-2.168-.143c-.134-.01-.219-.071-.219-.153v-1.734c0-.082.1-.143.219-.153l2.168-.143a24.846 24.846 0 013.261 0l2.168.143c.134.01.219.071.219.153v1.734c0 .082-.1.143-.219.153l-2.168.143c-.555.036-1.093.051-1.631.051zm-3.513-.479l1.933.122a23.092 23.092 0 003.143 0l1.933-.122v-1.459l-1.933-.122a23.092 23.092 0 00-3.143 0l-1.933.122z"
          transform="translate(-267.164 -475.567)"
        ></path>
        <path
          fill="#f69ea8"
          d="M301.7 505.9a4.571 4.571 0 01-1.4.082v1.275a4.574 4.574 0 011.4.082z"
          transform="translate(-261.507 -480.274)"
        ></path>
        <path
          fill="#4f3460"
          d="M300.53 506.289a.339.339 0 01-.1-.01 4.259 4.259 0 00-1.244-.071.429.429 0 01-.2-.031.148.148 0 01-.084-.112v-1.275a.134.134 0 01.084-.112.331.331 0 01.2-.031 4.15 4.15 0 001.244-.071.365.365 0 01.235.01c.067.031.118.082.118.133v1.418c0 .051-.05.1-.118.133a.809.809 0 01-.135.019zm-.924-.408a5.3 5.3 0 01.672.041V504.9a5.6 5.6 0 01-.891.041v.938z"
          transform="translate(-260.342 -479.036)"
        ></path>
        <path
          fill="#f27293"
          d="M0 0H0.958V1.673H0z"
          transform="translate(37.936 25.5)"
        ></path>
        <path
          fill="#4f3460"
          d="M294.91 504.979h-.958c-.134 0-.252-.071-.252-.153v-1.673c0-.082.118-.153.252-.153h.958c.134 0 .252.071.252.153v1.673c0 .081-.117.153-.252.153zm-.706-.306h.454v-1.367h-.458z"
          transform="translate(-256.016 -477.618)"
        ></path>
        <path
          fill="#8ed8f8"
          d="M0 0H4.891V0.632H0z"
          transform="translate(33.062 25.991)"
        ></path>
        <path
          fill="#4f3460"
          d="M269.743 509.639h-4.891c-.134 0-.252-.071-.252-.153v-.632c0-.082.118-.153.252-.153h4.891c.134 0 .252.071.252.153v.632c.005.081-.1.153-.252.153zm-4.622-.306h4.387v-.326h-4.387z"
          transform="translate(-231.807 -482.83)"
        ></path>
        <path
          fill="#ccecfc"
          d="M250.8 507.214v.867l2.168.214.706-.214v-.867l-.706-.214z"
          transform="translate(-220.327 -481.263)"
        ></path>
        <path
          fill="#4f3460"
          d="M251.72 507.1h-.034l-2.168-.214c-.118-.01-.219-.071-.219-.153v-.867c0-.071.084-.143.219-.153l2.168-.214a.756.756 0 01.151.01l.706.214c.084.031.134.082.134.133v.867c0 .061-.05.112-.134.133l-.706.214a.253.253 0 01-.117.03zm-1.916-.5l1.883.184.5-.153v-.673l-.5-.153-1.883.184z"
          transform="translate(-219.079 -479.882)"
        ></path>
        <path
          fill="#afd46e"
          d="M175.347 502.471v1.734l-2.168.143a24.086 24.086 0 01-3.21 0l-2.168-.143v-1.734l2.168-.143a24.086 24.086 0 013.21 0z"
          transform="translate(-151.278 -476.95)"
        ></path>
        <path
          fill="#4f3460"
          d="M170.417 503.2c-.538 0-1.093-.02-1.63-.051l-2.168-.143c-.134-.01-.219-.071-.219-.153v-1.734c0-.082.1-.143.219-.153l2.168-.143a24.841 24.841 0 013.261 0l2.168.143c.134.01.219.071.219.153v1.734c0 .082-.1.143-.219.153l-2.168.143c-.538.036-1.093.051-1.631.051zm-3.513-.479l1.933.122a23.092 23.092 0 003.143 0l1.933-.122v-1.459l-1.933-.122a23.092 23.092 0 00-3.143 0l-1.933.122z"
          transform="translate(-150.113 -475.567)"
        ></path>
        <path
          fill="#ccecfc"
          d="M161 505.9a4.571 4.571 0 01-1.4.082v1.275a4.572 4.572 0 011.4.082z"
          transform="translate(-144.456 -480.274)"
        ></path>
        <path
          fill="#4f3460"
          d="M159.83 506.289a.34.34 0 01-.1-.01 4.259 4.259 0 00-1.244-.071.429.429 0 01-.2-.031.148.148 0 01-.084-.112v-1.275a.134.134 0 01.084-.112.331.331 0 01.2-.031 4.15 4.15 0 001.244-.071.365.365 0 01.235.01c.067.031.118.082.118.133v1.418c0 .051-.05.1-.118.133a.613.613 0 01-.135.019zm-.924-.408a5.3 5.3 0 01.672.041V504.9a5.6 5.6 0 01-.891.041v.938z"
          transform="translate(-143.291 -479.036)"
        ></path>
        <path
          fill="#afd46e"
          d="M0 0H0.958V1.673H0z"
          transform="translate(14.304 25.5)"
        ></path>
        <path
          fill="#4f3460"
          d="M154.21 504.979h-.958c-.134 0-.252-.071-.252-.153v-1.673c0-.082.118-.153.252-.153h.958c.134 0 .252.071.252.153v1.673c0 .081-.101.153-.252.153zm-.689-.306h.454v-1.367h-.454z"
          transform="translate(-138.965 -477.618)"
        ></path>
        <path
          fill="#8ed8f8"
          d="M0 0H4.891V0.632H0z"
          transform="translate(9.413 25.991)"
        ></path>
        <path
          fill="#4f3460"
          d="M129.143 509.639h-4.891c-.134 0-.252-.071-.252-.153v-.632c0-.082.118-.153.252-.153h4.891c.134 0 .252.071.252.153v.632c.005.081-.117.153-.252.153zm-4.639-.306h4.387v-.326H124.5z"
          transform="translate(-114.84 -482.83)"
        ></path>
        <path
          fill="#ccecfc"
          d="M110.1 507.214v.867l2.168.214.723-.214v-.867l-.723-.214z"
          transform="translate(-103.276 -481.263)"
        ></path>
        <path
          fill="#4f3460"
          d="M111.02 507.1h-.034l-2.168-.214c-.118-.01-.219-.071-.219-.153v-.867c0-.071.084-.143.219-.153l2.168-.214a.758.758 0 01.151.01l.706.214c.084.031.134.082.134.133v.867c0 .061-.05.112-.134.133l-.706.214a.209.209 0 01-.117.03zm-1.916-.5l1.883.184.5-.153v-.673l-.5-.153-1.883.184z"
          transform="translate(-102.028 -479.882)"
        ></path>
        <path
          fill="#ccecfc"
          d="M267.6 394.3l-.017-.02a.934.934 0 01.017-1h1.563l-.3-.367a.118.118 0 00-.084-.041 4.72 4.72 0 00-1.866.092.53.53 0 00-.2.173.871.871 0 01-.706.275l-7.816-.051a1 1 0 01-.807-.316.885.885 0 00-.219-.2 5.626 5.626 0 00-2.118-.1.128.128 0 00-.1.041l-.353.418h1.765a1.1 1.1 0 01.017 1.132l-.017.02s-1.361.031-2.051 0l.387.388a.206.206 0 00.134.051 6.891 6.891 0 002.336-.1 1.182 1.182 0 00.2-.194 1 1 0 01.807-.326l7.866-.051a.852.852 0 01.706.286.588.588 0 00.168.173 6.266 6.266 0 002.051.092.139.139 0 00.118-.051l.336-.337c-.628.044-1.817.013-1.817.013z"
          transform="translate(-223.238 -382.585)"
        ></path>
        <path
          fill="#4f3460"
          d="M254.729 393.719c-.4 0-.824-.02-1.21-.051a.552.552 0 01-.319-.122l-.387-.388a.107.107 0 010-.163.287.287 0 01.235-.071c.538.02 1.462.01 1.849 0a.96.96 0 000-.857h-1.58a.365.365 0 01-.217-.067.109.109 0 01-.017-.153l.353-.418a.4.4 0 01.286-.122 5.6 5.6 0 012.32.133.7.7 0 01.286.255.729.729 0 00.571.224l7.816.051a.6.6 0 00.471-.184.813.813 0 01.269-.235 4.767 4.767 0 012.067-.122.4.4 0 01.269.122l.3.367a.11.11 0 01-.017.153.309.309 0 01-.219.071h-1.378a.88.88 0 000 .714c.353.01 1.143.02 1.614 0a.327.327 0 01.235.071.107.107 0 010 .163l-.336.337a.482.482 0 01-.3.122 5.423 5.423 0 01-2.269-.133.7.7 0 01-.219-.214.585.585 0 00-.487-.194l-7.849.051a.737.737 0 00-.588.235.791.791 0 01-.252.245 3.021 3.021 0 01-1.3.18zm-1.126-.357a6.061 6.061 0 002.084-.051.537.537 0 00.151-.153 1.3 1.3 0 011.042-.418l7.849-.051a1.154 1.154 0 01.941.377.486.486 0 00.118.122 5.124 5.124 0 001.8.041l.067-.071c-.605.01-1.328-.01-1.361-.01a.277.277 0 01-.235-.1l-.017-.02a1.042 1.042 0 01.017-1.1.277.277 0 01.235-.1h1.143l-.084-.1a4.2 4.2 0 00-1.6.061.407.407 0 00-.134.122 1.175 1.175 0 01-.941.367l-7.816-.051a1.265 1.265 0 01-1.025-.408.649.649 0 00-.151-.143 4.84 4.84 0 00-1.849-.071l-.134.163h1.361a.277.277 0 01.235.1 1.2 1.2 0 01.017 1.234l-.017.02a.257.257 0 01-.235.1c-.05 0-.908.02-1.614.01z"
          transform="translate(-221.97 -381.305)"
        ></path>
        <path
          fill="#ccecfc"
          d="M264.421 448.6l-.017-.02a.934.934 0 01.017-1h1.563l-.3-.367a.118.118 0 00-.084-.041 4.716 4.716 0 00-1.866.092.531.531 0 00-.2.173.871.871 0 01-.706.275l-10.841-.051a1 1 0 01-.807-.316.887.887 0 00-.219-.2 5.631 5.631 0 00-2.118-.1.128.128 0 00-.1.041l-.353.418h1.765a1.1 1.1 0 01.017 1.132l-.017.02s-1.361.031-2.051 0l.387.388a.206.206 0 00.134.051 6.891 6.891 0 002.336-.1 1.183 1.183 0 00.2-.194 1 1 0 01.807-.326l10.892-.051a.852.852 0 01.706.286.588.588 0 00.168.173 6.265 6.265 0 002.051.092.138.138 0 00.118-.051l.336-.337c-.625.044-1.818.013-1.818.013z"
          transform="translate(-218.08 -429.338)"
        ></path>
        <path
          fill="#4f3460"
          d="M248.629 448.019c-.4 0-.824-.02-1.21-.051a.552.552 0 01-.319-.122l-.387-.388a.107.107 0 010-.163.327.327 0 01.235-.071c.538.02 1.462.01 1.849 0a.96.96 0 000-.857h-1.58a.366.366 0 01-.217-.067.109.109 0 01-.017-.153l.336-.418a.4.4 0 01.286-.122 5.6 5.6 0 012.32.133.7.7 0 01.286.255.728.728 0 00.571.224l10.841.051a.6.6 0 00.471-.184.813.813 0 01.269-.235 4.766 4.766 0 012.067-.122.4.4 0 01.269.122l.3.367a.11.11 0 01-.017.153.309.309 0 01-.218.071h-1.378a.88.88 0 000 .714c.353.01 1.143.02 1.614 0a.327.327 0 01.235.071.107.107 0 010 .163l-.336.337a.482.482 0 01-.3.122 5.424 5.424 0 01-2.269-.133.7.7 0 01-.219-.214.585.585 0 00-.487-.194l-10.875.051a.677.677 0 00-.572.235.791.791 0 01-.252.245 3.023 3.023 0 01-1.296.18zm-1.126-.357a6.06 6.06 0 002.084-.051.537.537 0 00.151-.153 1.3 1.3 0 011.042-.418l10.875-.051a1.154 1.154 0 01.941.377.487.487 0 00.118.122 5.126 5.126 0 001.8.041l.067-.071c-.605.01-1.328-.01-1.361-.01a.277.277 0 01-.235-.1l-.017-.02a1.042 1.042 0 01.017-1.1.277.277 0 01.235-.1h1.143l-.084-.1a4.2 4.2 0 00-1.6.061.407.407 0 00-.134.122 1.175 1.175 0 01-.941.367l-10.841-.051a1.266 1.266 0 01-1.025-.408.649.649 0 00-.151-.143 4.838 4.838 0 00-1.849-.071l-.134.163h1.361a.277.277 0 01.235.1 1.2 1.2 0 01.017 1.234l-.017.02a.257.257 0 01-.235.1c-.05 0-.908.02-1.614.01z"
          transform="translate(-216.895 -428.03)"
        ></path>
        <path
          fill="#8ed8f8"
          d="M0 0H31.162V1.561H0z"
          transform="translate(11.177 .918)"
        ></path>
        <path
          fill="#4f3460"
          d="M165.247 330.561H134.6c-.336 0-.6-.163-.6-.367v-.826c0-.2.269-.367.6-.367h30.63c.336 0 .6.163.6.367v.826c.022.206-.247.367-.583.367zm-30.574-.408h30.49v-.745h-30.49z"
          transform="translate(-123.159 -328.082)"
        ></path>
        <path
          fill="#8ed8f8"
          d="M134.07 449.582l-.017-.031a1.257 1.257 0 01.017-1.306h2.051l-.4-.479a.139.139 0 00-.118-.051 6.272 6.272 0 00-2.437.112.866.866 0 00-.269.235 1.137 1.137 0 01-.924.367h-11a2.011 2.011 0 00-1.5-.551c-.924 0-1.681.459-1.681 1.02s.756 1.02 1.681 1.02a2.039 2.039 0 001.5-.551h11.009a1.2 1.2 0 01.941.377.678.678 0 00.235.224 7.669 7.669 0 002.689.112.223.223 0 00.151-.061l.437-.449c-.801.043-2.365.012-2.365.012zm-14.57-.282c-.353 0-.656-.173-.656-.4s.286-.4.656-.4.656.173.656.4c-.02.213-.305.4-.656.4z"
          transform="translate(-109.682 -429.835)"
        ></path>
        <path
          fill="#4f3460"
          d="M133.326 448.9a3.355 3.355 0 01-1.513-.224.949.949 0 01-.269-.275.875.875 0 00-.706-.286h-10.875a2.407 2.407 0 01-1.63.551c-1.059 0-1.933-.53-1.933-1.173s.874-1.173 1.933-1.173a2.358 2.358 0 011.63.551H130.8a.866.866 0 00.689-.275.832.832 0 01.336-.286 6.436 6.436 0 012.639-.153.451.451 0 01.3.133l.4.479a.11.11 0 01-.017.153.31.31 0 01-.219.071h-1.866a1.116 1.116 0 000 1.03c.437.01 1.546.02 2.168 0a.326.326 0 01.235.071.107.107 0 010 .163l-.437.449a.545.545 0 01-.336.133c-.358.032-.862.061-1.366.061zm-13.514-1.081h11.009a1.474 1.474 0 011.177.469.562.562 0 00.185.184 7.235 7.235 0 002.454.071l.185-.194c-.824.01-1.883-.01-1.933-.01a.277.277 0 01-.235-.1l-.017-.031a1.329 1.329 0 01.017-1.408.278.278 0 01.235-.1h1.63l-.185-.235a5.688 5.688 0 00-2.185.082.64.64 0 00-.185.173 1.465 1.465 0 01-1.16.459h-10.992a.263.263 0 01-.219-.082 1.725 1.725 0 00-1.261-.469c-.79 0-1.429.388-1.429.867s.639.867 1.429.867a1.658 1.658 0 001.261-.469.366.366 0 01.219-.073zm-1.479.235c-.5 0-.908-.245-.908-.551s.4-.551.908-.551.908.245.908.551-.421.552-.908.552zm0-.8c-.219 0-.4.112-.4.245s.185.245.4.245.4-.112.4-.245-.182-.24-.4-.24z"
          transform="translate(-108.517 -428.432)"
        ></path>
        <path
          fill="#8ed8f8"
          d="M131.213 393.082l-.017-.031a1.257 1.257 0 01.017-1.306h2.051l-.4-.479a.138.138 0 00-.118-.051 6.272 6.272 0 00-2.437.112.866.866 0 00-.269.235 1.137 1.137 0 01-.924.367h-8.135a2.011 2.011 0 00-1.5-.551c-.924 0-1.681.459-1.681 1.02s.756 1.02 1.681 1.02a2.039 2.039 0 001.5-.551h8.152a1.2 1.2 0 01.941.377.679.679 0 00.235.224 7.669 7.669 0 002.689.112.222.222 0 00.151-.061l.437-.449c-.81.043-2.373.012-2.373.012zM119.5 392.8c-.353 0-.656-.173-.656-.4s.286-.4.656-.4.656.173.656.4c-.02.213-.305.4-.656.4z"
          transform="translate(-109.682 -381.22)"
        ></path>
        <path
          fill="#4f3460"
          d="M130.469 392.4a3.356 3.356 0 01-1.513-.224.949.949 0 01-.269-.275.875.875 0 00-.706-.286h-8.018a2.408 2.408 0 01-1.63.551c-1.059 0-1.933-.53-1.933-1.173s.874-1.173 1.933-1.173a2.358 2.358 0 011.63.551h7.984a.866.866 0 00.689-.275.833.833 0 01.336-.286 6.438 6.438 0 012.639-.153.451.451 0 01.3.133l.4.479a.11.11 0 01-.017.153.309.309 0 01-.218.071h-1.866a1.115 1.115 0 000 1.03c.437.01 1.546.02 2.168 0a.327.327 0 01.235.071.107.107 0 010 .163l-.437.449a.546.546 0 01-.336.133 16.33 16.33 0 01-1.371.061zm-10.656-1.081h8.152a1.474 1.474 0 011.177.469.561.561 0 00.185.184 7.236 7.236 0 002.454.071l.185-.194c-.824.01-1.883-.01-1.933-.01a.277.277 0 01-.235-.1l-.017-.031a1.329 1.329 0 01.017-1.408.277.277 0 01.235-.1h1.63l-.185-.235a5.687 5.687 0 00-2.185.082.638.638 0 00-.185.173 1.465 1.465 0 01-1.16.459h-8.135a.263.263 0 01-.219-.082 1.725 1.725 0 00-1.261-.469c-.79 0-1.429.388-1.429.867s.639.867 1.429.867a1.659 1.659 0 001.261-.469.365.365 0 01.218-.073zm-1.479.235c-.5 0-.908-.245-.908-.551s.4-.551.908-.551.908.245.908.551-.422.552-.909.552zm0-.8c-.219 0-.4.112-.4.245s.185.245.4.245.4-.112.4-.245-.183-.24-.4-.24z"
          transform="translate(-108.517 -379.846)"
        ></path>
      </g>
    </svg>
  );
}
export default Tools;
